<template>
  <v-container class="componentlibrary">
    <v-row>
      <v-col style="max-width:56px">
        <r-navigation-drawer
          title="Component Library"
          style="position:fixed;z-index:1"
          :items="navigationItems"
        />
      </v-col>
      <v-col>
        <r-page-header
          :label="$t('componentLibrary.welcomeMsg')"
          class="mb-16"
        >
          <template v-slot:end>
            <r-button
              v-for="entry in languages"
              :key="entry.title"
              class="ml-2"
              :label="entry.title"
              :outlined="!isLanguageActive(entry.language)"
              @click="changeLocale(entry.language)"
            />
          </template>
        </r-page-header>

        <r-section
          ref="rbutton"
          label="RButton"
        >
          <template v-slot:content>
            <r-button
              color="normal"
              :label="$t('global.actions.search')"
              icon="search"
              class="mr-2"
            />
            <r-button
              type="normal"
              :label="$t('global.actions.search')"
              icon=""
              :outlined="true"
            />
          </template>
        </r-section>

        <r-section
          ref="rchip"
          label="RChip"
        >
          <template v-slot:content>
            <v-chip-group>
              <r-chip
                :label="$t('componentLibrary.filter', { num: 1 })"
                removable
              />
              <r-chip
                :label="$t('componentLibrary.filter', { num: 2 })"
                removable
              />
              <r-chip
                :label="$t('componentLibrary.filter', { num: 3 })"
                removable
              />
            </v-chip-group>
            <v-chip-group>
              <r-chip
                :label="$t('componentLibrary.filter', { num: 1 })"
                textonly
              />
              <r-chip
                :label="$t('componentLibrary.filter', { num: 2 })"
                textonly
                outlined
              />
              <r-chip
                :label="$t('componentLibrary.filter', { num: 3 })"
                textonly
              />
            </v-chip-group>
            <v-chip-group>
              <r-chip
                :label="$t('componentLibrary.filter', { num: 1 })"
                :width="50"
              />
              <r-chip
                :label="$t('componentLibrary.filter', { num: 1 })"
                :width="187"
              />
            </v-chip-group>
          </template>
        </r-section>

        <r-section
          ref="rmodal"
          label="RModal"
        >
          <template v-slot:content>
            <r-button
              ref="rmodal-trigger"
              type="primary"
              :label="$t('componentLibrary.openModal')"
              @click="toggleExampleModal"
            />
            <r-modal
              v-if="rButtonShowExampleModal"
              title="Example Modal"
              :close-action-label="$t('global.actions.cancel')"
              :action-label="$t('componentLibrary.submit')"
              :alternate-action-label="$t('componentLibrary.alternateSubmit')"
              :fetching="false"
              :action-completed="false"
              @submit="toggleExampleModal"
              @submit:alternate="toggleExampleModal"
              @close="toggleExampleModal"
            >
              <template v-slot:content>
                <div>
                  <label>An static content</label>
                </div>
              </template>
            </r-modal>
          </template>
        </r-section>

        <r-section
          ref="rsearchinput"
          label="RSearchInput"
        >
          <template v-slot:content>
            <r-search-input
              :placeholder="$t('componentLibrary.searchResource', {
                resource: $t('resources.product')
              })"
              :value="rSearchInputValue"
              @change="onInputChange"
            />
            <p>
              {{ $t('componentLibrary.valueIs') }}: {{ rSearchInputValue }}
            </p>
          </template>
        </r-section>

        <r-section
          ref="rsectionheader"
          label="RComponentHeader"
        >
          <template v-slot:content>
            <r-component-header>
              {{ $t('componentLibrary.exampleSectionHeader') }}
            </r-component-header>
          </template>
        </r-section>

        <r-section
          ref="rsectionheader"
          label="RSectionHeader"
        >
          <template v-slot:content>
            <r-section-header :label="$t('componentLibrary.exampleSectionHeader')" />
          </template>
        </r-section>

        <r-section
          ref="rsection"
          label="RSection"
        >
          <template v-slot:content>
            <r-section
              label="Buttons Components"
              :collapsable="true"
            >
              <template v-slot:content>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pharetra
                    malesuada velit,
                    non venenatis velit vestibulum vitae. Fusce eget pretium arcu. Morbi tincidunt
                    scelerisque ante et sodales. Donec eget purus eget enim hendrerit dignissim.
                    Nullam et pellentesque felis. Duis mollis non augue ac pulvinar. Aliquam at
                    mi non turpis dignissim gravida. Donec pharetra vitae mauris nec tristique.
                  </p>

                  <p>
                    Vivamus risus nulla, laoreet sit amet nisi sit amet, bibendum hendrerit urna.
                    Donec sit amet laoreet arcu. Ut tempus metus nec nisl luctus mattis. Donec
                    efficitur nisl id lectus efficitur, a vestibulum tellus scelerisque. Morbi
                    dolor turpis, aliquam eu fermentum vitae, molestie vitae nisl. Donec augue
                    purus, fermentum quis suscipit eu, accumsan eu orci. Nunc libero orci,
                    sollicitudin eget accumsan in, molestie ut leo. Quisque vestibulum accumsan
                    risus, quis bibendum magna interdum eu. Nunc euismod mauris et laoreet maximus.
                    Etiam dui tellus, lacinia in posuere id, fringilla euismod mauris. Ut non
                    dictum metus. Quisque malesuada nibh interdum mi tempor vestibulum. Nulla
                    pellentesque vehicula euismod. Curabitur sollicitudin efficitur efficitur. In
                    non luctus metus. Nam tempor luctus lobortis.
                  </p>
                </div>
              </template>
            </r-section>
          </template>
        </r-section>

        <r-section
          ref="rfileupload"
          label="RFileUpload"
        >
          <template v-slot:content>
            <r-file-upload
              :label="$t('componentLibrary.uploadFileLabel')"
              :placeholder="$t('componentLibrary.uploadFilePlaceholder')"
              :filetypes="['.txt']"
              :select-file-label="$t('componentLibrary.uploadFileSelectLabel')"
              :upload-button-label="$t('componentLibrary.uploadButtonLabel')"
              @file="onFileUploadFile"
              @data="onFileUploadData"
              @upload="onFileUploadUpload"
            />
            <p>
              Uploaded file name: {{ fileUploadFilename }}
            </p>
            <p>
              Uploaded file data length: {{ fileUploadData.length }}
            </p>
          </template>
        </r-section>

        <r-section
          ref="rdataimage"
          label="RDataImage"
        >
          <template v-slot:content>
            <v-row>
              <v-col md="1">
                <p> Valid url </p>
                <r-data-image
                  :ids="['https://i.picsum.photos/id/732/510/300.jpg?hmac=QZJQGU9iS24mIgx_75CDQbfzjceydJS5mHNEUXVampE']"
                />
              </v-col>
              <v-col md="1">
                <p> Invalid URL </p>
                <r-data-image
                  :ids="['https://bad.src/not/valid']"
                />
              </v-col>
              <v-col md="1">
                <p> 404 URL </p>
                <r-data-image
                  :ids="['https://i.picsum.photos/id/000/510/300.jpg?hmac=']"
                />
              </v-col>
              <v-col md="1">
                <p> Product </p>
                <r-data-image
                  :type="DASHBOARD_TYPES.PRODUCT"
                  :ids="['https://picsum.photos/510/300?random']"
                />
              </v-col>
              <v-col md="1">
                <p> Tag </p>
                <r-data-image
                  :type="DASHBOARD_TYPES.TAG"
                  :ids="['https://picsum.photos/500/300?random']"
                />
              </v-col>
              <v-col md="1">
                <p> Dashboard </p>
                <r-data-image
                  :type="DASHBOARD_TYPES.DASHBOARD"
                  :ids="[
                    'https://picsum.photos/520/300?random',
                    'https://i.picsum.photos/id/586/520/300.jpg?hmac=qRtbjEnAtlrcWaJ7O4XyUXa1h50ATDi8TA6QvOd59IE',
                  ]"
                />
              </v-col>
              <v-col md="1">
                <p> Dashboard </p>
                <r-data-image
                  :type="DASHBOARD_TYPES.DASHBOARD"
                  :ids="[
                    'https://i.picsum.photos/id/568/520/300.jpg?hmac=CdChp0Y6odWjIYMCjGm_PCto5_eJPkwI8DGxmaLzV6w',
                    'https://i.picsum.photos/id/586/520/300.jpg?hmac=qRtbjEnAtlrcWaJ7O4XyUXa1h50ATDi8TA6QvOd59IE',
                    'https://i.picsum.photos/id/534/520/300.jpg?hmac=hxHVPbOozUzmNL9XRmGp-qXMmWK6dSwWCJHhJ5jWAcU'
                  ]"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rdatacard"
          label="RDataCard"
        >
          <template v-slot:content>
            <v-row>
              <v-col md="3">
                <p> Product Card </p>
                <r-data-card
                  id="test-2"
                  name="Nutella"
                  :type="DASHBOARD_TYPES.PRODUCT"
                  :bookmark="true"
                  :num-products="1"
                  :num-reviews="1"
                  :num-urls="1"
                  :rating="2"
                  :sentiment-distribution="SENTIMENT_DISTRIBUTION_EXAMPLE"
                  :progress-percent="70"
                  last-update="2022-03-30T00:00:00.218Z"
                  modified="2022-03-30T00:00:00.218Z"
                  bcr-link-id="bcr-link-id"
                />
              </v-col>
              <v-col md="3">
                <p> Tag Cards </p>
                <r-data-card
                  id="test-3"
                  name="Chocolate spreads"
                  :type="DASHBOARD_TYPES.TAG"
                  :num-products="12"
                  :num-reviews="12"
                  :num-urls="11"
                  :bookmark="true"
                  :rating-distribution="RATING_DISTRIBUTION_EXAMPLE"
                  last-update="2022-03-28T00:00:00.218Z"
                  modified="2022-03-30T00:00:00.218Z"
                />
              </v-col>
              <v-col md="3">
                <p> Dashboard Card </p>
                <r-data-card
                  id="test-4"
                  :type="DASHBOARD_TYPES.DASHBOARD"
                  name="Nutella vs Chocolate Spreads"
                  :num-products="11"
                  :num-reviews="12"
                  :num-urls="13"
                  :last-update="today"
                  :rating-distribution="RATING_DISTRIBUTION_EXAMPLE"
                  modified="2022-03-30T00:00:00.218Z"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="ralertcard"
          label="RAlertCard"
        >
          <template v-slot:content>
            <v-row>
              <v-col md="3">
                <r-alert-card
                  :type="ALERT_TYPES.URL_ERROR"
                  date="2022-03-30T00:00:00.218Z"
                  :data="{}"
                />
              </v-col>
              <v-col md="3">
                <r-alert-card
                  :data="{
                    searchName: 'TVs',
                    newFounds: 45,
                  }"
                  :type="ALERT_TYPES.SEARCH_NEW_FOUNDS"
                  date="2022-03-30T00:00:00.218Z"
                />
              </v-col>
              <v-col md="3">
                <r-alert-card
                  :type="ALERT_TYPES.NEW_PRODUCT"
                  :data="{
                    uploadName: 'Electronics',
                    numProduct: 1,
                  }"
                  date="2022-03-30T00:00:00.218Z"
                />
              </v-col>
              <v-col md="3">
                <r-alert-card
                  :type="ALERT_TYPES.UPLOAD_COMPLETED"
                  :data="{ uploadName: 'Electronics' }"
                  date="2022-03-30T00:00:00.218Z"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rpageheader"
          label="RPageHeader"
        >
          <template v-slot:content>
            <r-page-header label="A page header" />
            <r-page-header
              label="With a back button"
              previousroute="/"
            />
            <r-page-header label="With a postlabel slot">
              <template v-slot:postlabel>
                <r-button :label="$t('global.actions.save')" />
              </template>
            </r-page-header>
            <r-page-header label="With an end slot">
              <template v-slot:end>
                <r-search-input
                  placeholder="Search something"
                  style="max-width:200px"
                />
              </template>
            </r-page-header>
          </template>
        </r-section>

        <r-section
          ref="rbreadcrumbs"
          label="RBreadcrumbs"
        >
          <template v-slot:content>
            <r-breadcrumbs
              :items="[
                {
                  label: 'Home',
                  routePath: '/'
                },
                {
                  label: 'Dashboards',
                  routePath: '/dashboards'
                },
                {
                  label: 'Components',
                  routePath: '/components'
                }
              ]"
            />
          </template>
        </r-section>

        <r-section
          ref="rselect"
          label="RSelect"
        >
          <template v-slot:content>
            <r-select
              placeholder="Single select"
              :items="[
                { label: 'hello', value:'foo', selected: false },
                { label: 'hi!', value:'bar', selected: false },
              ]"
            />
            <r-select
              placeholder="Single select with a selected value"
              :items="[
                { label: 'hello', value:'foo2', selected: false },
                { label: 'hi!', value:'bar2', selected: true },
              ]"
            />
            <r-select
              placeholder="Multiple select"
              multiple
              :items="[
                { label: 'hello', value:'foo3', selected: false },
                { label: 'hi!', value:'bar3', selected: false },
                { label: 'good day!', value:'bish3', selected: false },
                { label: 'Bonjour', value:'bosh3', selected: false },
                { label: 'Kalispera', value:'bash3', selected: false },
              ]"
            />
            <r-select
              placeholder="Multiple select with selected values"
              multiple
              :items="[
                { label: 'hello', value:'foo4', selected: true },
                { label: 'hi!', value:'bar4', selected: false },
                { label: 'good day!', value:'bish4', selected: true },
                { label: 'Bonjour', value:'bosh4', selected: false },
                { label: 'Kalispera', value:'bash4', selected: false },
              ]"
            />
          </template>
        </r-section>

        <r-section
          ref="ranchoredpopup"
          label="RAnchoredPopup"
        >
          <template v-slot:content>
            <r-anchored-popup
              v-slot="{ onClose }"
              label="Filters"
              icon="filter_list"
              anchor="top-right"
            >
              <v-card-text>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel quibusdam
                reprehenderit, aliquid recusandae perferendis ut eaque velit neque quis nam
                voluptas voluptatem debitis dignissimos,
                cumque eveniet id animi consequuntur quae?
              </v-card-text>
              <v-card-actions class="justify-end">
                <r-button
                  label="cancel"
                  type="normal"
                  @click="onClose"
                />
                <r-button
                  label="apply"
                  @click="onClose"
                />
              </v-card-actions>
            </r-anchored-popup>
          </template>
        </r-section>

        <r-section
          ref="rtabs"
          label="RTabs"
        >
          <template v-slot:content>
            <r-tabs
              :items="tabItems"
              :selected-tab="selectedTab"
              @change="onChangeTab"
            />
            <r-tab-items :selected-tab="selectedTab">
              <slot>
                <r-tab-item
                  v-for="item in tabItems"
                  :key="item.label"
                  :label="item.label"
                >
                  <slot>
                    <div class="custom-tab-item-style">
                      <label>{{ item.content }}</label>
                    </div>
                  </slot>
                </r-tab-item>
              </slot>
            </r-tab-items>
            <r-section-header
              label="Entity progress"
              :collapsable="false"
            >
              <template v-slot:postlabel>
                <r-tabs
                  :items="tabItems"
                  :selected-tab="selectedTab"
                  @change="onChangeTab"
                />
              </template>
            </r-section-header>
            <r-tab-items :selected-tab="selectedTab">
              <template v-slot:default>
                <r-tab-item
                  v-for="item in tabItems"
                  :key="item.label"
                  :label="item.label"
                >
                  <template v-slot:default>
                    <label>{{ item.content }}</label>
                  </template>
                </r-tab-item>
              </template>
            </r-tab-items>
          </template>
        </r-section>

        <r-section
          ref="rnavigationdrawer"
          label="RNavigationDrawer"
        >
          <template v-slot:content>
            <r-navigation-drawer
              title="Test navigation"
              style="height:200px"
              :items="[
                { title: 'Hello', icon:'grade', ref: 'testRef' },
                { title: 'Hi!', icon:'bolt', ref: 'testRef2' },
              ]"
            />
          </template>
        </r-section>

        <r-section
          ref="rbarchart"
          label="RBarChart"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <p>Horizontal Chart</p>
                <r-bar-chart
                  :data="[
                    {value: 3},
                    {value: 20},
                    {value: 50},
                  ]"
                  :vertical="false"
                />
              </v-col>
              <v-col>
                <p>Vertical Chat</p>
                <r-bar-chart
                  :data="[
                    {value: 3},
                    {value: 20},
                    {value: 50},
                  ]"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rpiechart"
          label="RPieChart"
        >
          <template v-slot:content>
            <r-pie-chart :data="PIECHART_DATA" />
          </template>
        </r-section>

        <r-section
          ref="rpagination"
          label="RPagination"
        >
          <template v-slot:content>
            <r-pagination
              :page="paginationPage"
              :total-pages="3456"
              @click:page="({ value }) => paginationPage = value"
            />
          </template>
        </r-section>

        <r-section
          ref="rtable"
          label="RTable"
        >
          <template v-slot:content>
            <r-table
              key="exampletables"
              :columns="tableColumns"
              :rows="tableRows"
              :total-rows="tableRows.length"
              :height="300"
            >
              <template v-slot:item.urls="{ item }">
                <div
                  class="text-truncate"
                  style="width:100px"
                >
                  <a href="#">{{ item.urls }}</a>
                </div>
              </template>
              <template v-slot:item.link="{ item }">
                <r-chip :label="item.link" />
              </template>
              <template v-slot:item.creationDate="{ item }">
                {{
                  item.creationDate.getDate()
                }}.{{
                  item.creationDate.getMonth() + 1
                }}.{{
                  item.creationDate.getFullYear()
                }}
              </template>
              <template v-slot:item.actions>
                <v-btn
                  icon
                  small
                >
                  <v-icon small>
                    cloud_upload
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon small>
                    delete
                  </v-icon>
                </v-btn>
              </template>
            </r-table>
          </template>
        </r-section>

        <r-section
          ref="rtextfield"
          label="RTextField"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <r-text-field
                  label="Text field"
                  placeholder="Eg name"
                  style="width:300px"
                  @change="onTextInputChange"
                />
                <p>
                  {{ $t('componentLibrary.valueIs') }}: {{ rTextInputValue }}
                </p>
              </v-col>
              <v-col>
                <r-text-field
                  label="Text field with rules applied"
                  placeholder="Eg name"
                  :rules="rTextFieldRules"
                  style="width:300px"
                  @change="onTextInputChange"
                />
                <p>
                  {{ $t('componentLibrary.valueIs') }}: {{ rTextInputValue }}
                </p>
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rbox"
          label="RBox"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <r-box title="URL details">
                  <p>
                    <b>Last edited:</b> 20.12.2022
                  </p>
                  <p>
                    <b>Creation date:</b> 20.12.2022
                  </p>
                </r-box>
              </v-col>
              <v-col>
                <r-box>
                  <r-bar-chart
                    :data="[
                      {value: 3},
                      {value: 20},
                      {value: 50},
                    ]"
                    :vertical="false"
                  />
                </r-box>
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="raccordion"
          label="RAccordion"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <p>First item opened:</p>
                <r-accordion
                  v-slot="{ activeItem, onChangeActive }"
                  :active-item="0"
                >
                  <r-accordion-item
                    v-for="(item, i) in 3"
                    :key="i"
                    :item-index="i"
                    :collapsed="activeItem !== i"
                    :title="`Item${++i}`"
                    @change="onChangeActive"
                  >
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia,
                      porro. Non a excepturi, voluptatibus ipsam magnam, eligendi,
                      accusantium ipsa quae quis praesentium voluptate saepe ullam sint ea
                      itaque consectetur impedit?
                    </p>
                  </r-accordion-item>
                </r-accordion>
              </v-col>
              <v-col>
                <p>All items closed:</p>
                <r-accordion v-slot="{ activeItem, onChangeActive }">
                  <r-accordion-item
                    v-for="(item, i) in 3"
                    :key="i"
                    :item-index="i"
                    :collapsed="activeItem !== i"
                    :title="`Item${++i}`"
                    @change="onChangeActive"
                  >
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia,
                      porro. Non a excepturi, voluptatibus ipsam magnam, eligendi,
                      accusantium ipsa quae quis praesentium voluptate saepe ullam sint ea
                      itaque consectetur impedit?
                    </p>
                  </r-accordion-item>
                </r-accordion>
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="raveragestarrating"
          label="RAverageStarRating"
        >
          <template v-slot:content>
            <r-average-star-rating
              :value="3.5"
              :title="$t('averageStarRating.title')"
            />
          </template>
        </r-section>

        <r-section
          ref="rbignumber"
          label="RBigNumber"
        >
          <template v-slot:content>
            <v-row>
              <v-col
                md="3"
                class="r-big-number"
              >
                <r-big-number
                  :value="4936100"
                  :title="$t('bigNumber.totalNumberOfReviews')"
                  :previous-value="4740"
                />
              </v-col>
              <v-col
                md="3"
                class="r-big-number"
              >
                <r-big-number
                  :value="9613050"
                  :title="$t('bigNumber.totalNumberOfReviews')"
                  :previous-value="7500"
                />
              </v-col>
              <v-col
                md="3"
                class="r-big-number"
              >
                <r-big-number
                  :value="313050"
                  :title="$t('bigNumber.totalNumberOfReviews')"
                  :previous-value="3687"
                />
              </v-col>
              <v-col
                md="3"
                class="r-big-number"
              >
                <r-big-number
                  :value="84443050"
                  :title="$t('bigNumber.totalNumberOfReviews')"
                  :previous-value="220"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rtimelinechart"
          collapsable
          label="RTimeLineChart"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <p>Line chart:</p>
                <r-time-line-chart
                  :data="[timeLineChartData[0]]"
                />
              </v-col>
              <v-col>
                <p>Spline chart:</p>
                <r-time-line-chart
                  spline
                  :data="[timeLineChartData[0]]"
                />
              </v-col>
              <v-col>
                <p>Chart with two series:</p>
                <r-time-line-chart
                  :data="timeLineChartData"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rstarratingdistributionchart"
          collapsable
          label="RStarRatingDistributionChart"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <p>Star Rating Distribution Chart:</p>
                <r-star-rating-distribution-chart
                  :title="rRatingDistributionChartTitle"
                  :data="rRatingDistributionChartData"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rnetsentimenthalfdonut"
          collapsable
          label="RNetSentimentHalfDonut"
        >
          <template v-slot:content>
            <v-row>
              <v-col
                cols="6"
              >
                <r-net-sentiment-half-donut
                  title="Net sentiment distribution (Vertical)"
                  :net-sentiment="0.6"
                  :num-negative="2"
                  :num-neutral="3"
                  :num-positive="10"
                />
              </v-col>
              <v-col>
                <div
                  style="width: 250px; height: 118px;"
                >
                  <r-net-sentiment-half-donut
                    title="Net sentiment distribution (Horizontal)"
                    horizontal
                    :net-sentiment="0.6"
                    :num-negative="2"
                    :num-neutral="3"
                    :num-positive="10"
                  />
                </div>
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rsentimentbarchart"
          collapsable
          label="Rsentimentbarchart"
        >
          <template v-slot:content>
            <v-row>
              <v-container>
                <v-col cols="3">
                  <r-sentiment-bar-chart
                    :data="RSENTIMENT_BAR_CHART_SAMPLE_DATA"
                  />
                </v-col>
              </v-container>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rwordcloud"
          collapsable
          label="RWordCloud"
        >
          <template v-slot:content>
            <r-word-cloud
              :entities="wordCloudData"
            />
          </template>
        </r-section>

        <r-section
          ref="rtrendingentities"
          collapsable
          label="RTrendingEntities"
        >
          <template v-slot:content>
            <v-row>
              <v-container>
                <v-col>
                  <r-trending-entities
                    :total-positive="2117"
                    :total-negative="1172"
                    :positive-percent-increase="6"
                    :negative-percent-increase="7"
                    :positive-data="RTRENDINGENTITIES_POSITIVE_SAMPLE_DATA"
                    :negative-data="RTRENDINGENTITIES_NEGATIVE_SAMPLE_DATA"
                    :positive-entities="['speakers', 'quality', 'price']"
                    :negative-entities="['speakers', 'quality', 'price']"
                  />
                </v-col>
              </v-container>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rsitesanalyzedtable"
          collapsable
          label="RSitesAnalyzedTable"
        >
          <template v-slot:content>
            <r-sites-analyzed-table
              :data="SITE_ANALYZED_TABLE_DATA"
              :loading="false"
            />
          </template>
        </r-section>

        <r-section
          ref="rcomparisonchart"
          collapsable
          label="RComparisonChart"
        >
          <template v-slot:content>
            <r-comparison-chart
              :data="COMPARISON_CHART_DATA.data"
              :breakdown-keys="COMPARISON_CHART_DATA.breakdownKeys"
            />
          </template>
        </r-section>

        <r-section
          ref="rmap"
          collapsable
          label="RMap"
        >
          <template v-slot:content>
            <r-map
              :data="MAP_DATA"
            />
          </template>
        </r-section>

        <r-section
          ref="rratingtimelinechart"
          collapsable
          label="RRatingTimeLineChart"
        >
          <template v-slot:content>
            <v-row>
              <v-col>
                <r-rating-time-line-chart
                  :title="$t('dashboards.components.averageStarRatingOverTime')"
                  :data="RRATING_TIME_LINE_CHART_SAMPLE_DATA"
                  :max-rate-value="5"
                  :rate-value="4.3"
                  :loading="false"
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rreviewcard"
          collapsable
          label="RReviewCard"
        >
          <template v-slot:content>
            <r-review-card
              :search="REVIEWCARD_DATA.search"
              :avatar-url="REVIEWCARD_DATA.avatarUrl"
              :username="REVIEWCARD_DATA.username"
              :date="REVIEWCARD_DATA.date"
              :url="REVIEWCARD_DATA.url"
              :rating="REVIEWCARD_DATA.rating"
              :title="REVIEWCARD_DATA.title"
              :original-title="REVIEWCARD_DATA.originalTitle"
              :review-id="REVIEWCARD_DATA.reviewId"
              :sentiment="REVIEWCARD_DATA.sentiment"
              :language="REVIEWCARD_DATA.language"
              :text="REVIEWCARD_DATA.text"
              :original-text="REVIEWCARD_DATA.originalText"
              :entities="REVIEWCARD_DATA.entities"
            />
          </template>
        </r-section>

        <r-section
          ref="rgroupedtabs"
          collapsable
          label="RGroupedTabs"
        >
          <template v-slot:content>
            <r-grouped-tabs
              ref="rgroupedtabs"
              :selected-group-tab="selectedGroupTab"
              :selected-sub-tab="selectedSubTab"
              :items="SINGLE_PRODUCT_GROUPED_TABS"
              @change="onChangeGroupTab"
              @change:sub-tab="onchangeSubTab"
            />
            <v-tabs-items
              v-if="selectedSubTab !== undefined"
              :value="selectedSubTab"
            >
              <v-tab-item
                v-for="i in SINGLE_PRODUCT_GROUPED_TABS[selectedGroupTab]['items']"
                :key="i.key"
              >
                {{ i.content }}
              </v-tab-item>
            </v-tabs-items>
          </template>
        </r-section>

        <r-section
          ref="rreviewcardlist"
          collapsable
          label="RReviewCardList"
        >
          <template v-slot:content>
            <r-review-card-list
              :reviews="REVIEWCARDLIST_DATA.reviews"
              :total-reviews="REVIEWCARDLIST_DATA.totalReviews"
              :page="REVIEWCARDLIST_DATA.page"
              :page-size="REVIEWCARDLIST_DATA.pageSize"
              :total-pages="REVIEWCARDLIST_DATA.totalPages"
            />
          </template>
        </r-section>

        <r-section
          ref="rtopicwheel"
          collapsable
          label="RTopicWheel"
        >
          <template v-slot:content>
            <v-row style="height: 600px;">
              <v-col cols="6">
                <r-topic-wheel
                  title="Topic Wheel (Flat)"
                  :data="TOPIC_WHEEL_DATA"
                />
              </v-col>
              <v-col cols="6">
                <r-topic-wheel
                  title="Topic Wheel (Hierarchical)"
                  :data="TOPIC_WHEEL_DATA"
                  hierarchical
                />
              </v-col>
            </v-row>
          </template>
        </r-section>

        <r-section
          ref="rtopsentimentreviewcards"
          collapsable
          label="RTopSentimentReviewCards"
        >
          <template v-slot:content>
            <r-top-sentiment-review-cards
              ref="rtopsentimentreviewcards"
              :positive-review="REVIEWCARD_DATA"
              :negative-review="REVIEWCARD_DATA_NEGATIVE"
            />
          </template>
        </r-section>

        <r-section
          ref="rtopurls"
          collapsable
          label="RTopUrls"
        >
          <template v-slot:content>
            <r-top-urls
              ref="rtopurls"
              :highest-rated="TOP_URL_DATA.highestRated"
              :most-reviewed="TOP_URL_DATA.mostReviewed"
            />
          </template>
        </r-section>

        <r-section
          ref="rdaterangepicker"
          collapsable
          label="RDateRangePicker"
        >
          <template v-slot:content>
            <r-date-range-picker
              ref="rdaterangepicker"
              style="position:static"
              :range="['2022-09-05', '2022-09-07']"
              :quick-selections="[
                {
                  label: 'Today',
                  value: {
                    startDate: '2022-09-05',
                    endDate: '2022-09-05',
                  },
                },
                {
                  label: '7 days',
                  value: {
                    startDate: '2022-08-30',
                    endDate: '2022-09-05',
                  },
                },
                {
                  label: '14 days',
                  value: {
                    startDate: '2022-08-23',
                    endDate: '2022-09-05',
                  },
                },
                {
                  label: '1 month',
                  value: {
                    startDate: '2022-08-07',
                    endDate: '2022-09-05',
                  },
                },
              ]"
            />
          </template>
        </r-section>

        <r-section
          ref="rratingtimelinecomparison"
          collapsable
          label="RRatingTimeLineComparison"
        >
          <template v-slot:content>
            <r-rating-time-line-comparison
              ref="rratingtimelinecomparison"
              :data="RRATING_TIME_LINE_COMPARISON_SAMPLE_DATA"
            />
          </template>
        </r-section>

        <r-section
          ref="rtextchips"
          collapsable
          label="RTextChips"
        >
          <template v-slot:content>
            <r-text-chips
              :chips="RTEXT_CHIPS_SAMPLE_DATA"
            />
          </template>
        </r-section>

        <r-section
          ref="rsitesanalyzed"
          collapsable
          label="RSitesAnalyzed"
        >
          <template v-slot:content>
            <p>One item in the table (hide the pie chart):</p>
            <r-sites-analyzed
              :data="SITE_ANALYZED_TABLE_DATA.slice(0, 1)"
            />
            <br><br>
            <p>More than one item in the table (show the pie chart):</p>
            <r-sites-analyzed
              :data="SITE_ANALYZED_TABLE_DATA.slice(0, 3)"
            />
          </template>
        </r-section>

        <r-section
          ref="rstackedbarchart"
          collapsable
          label="RStackedBarChart"
        >
          <template v-slot:content>
            <r-stacked-bar-chart
              :properties="RSTACKED_BAR_CHART_SAMPLE_DATA.properties"
              :data="RSTACKED_BAR_CHART_SAMPLE_DATA.data"
            />
          </template>
        </r-section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RNavigationDrawer from '@/components/library/organisms/RNavigationDrawer'
import RModal from '@/components/library/organisms/RModal'
import RButton from '@/components/library/atoms/RButton'
import RChip from '@/components/library/atoms/RChip'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RSection from '@/components/library/molecules/RSection'
import RSectionHeader from '@/components/library/molecules/RSectionHeader'
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RFileUpload from '@/components/library/molecules/RFileUpload'
import RDataImage from '@/components/library/atoms/RDataImage'
import RDataCard from '@/components/library/molecules/RDataCard'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RBreadcrumbs from '@/components/library/molecules/RBreadcrumbs'
import RSelect from '@/components/library/molecules/RSelect'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import RTabs from '@/components/library/molecules/RTabs'
import RTabItems from '@/components/library/molecules/RTabItems'
import RTabItem from '@/components/library/molecules/RTabItem'
import RBarChart from '@/components/library/atoms/RBarChart'
import RPieChart from '@/components/library/atoms/RPieChart'
import RTextField from '@/components/library/molecules/RTextField'
import RBox from '@/components/library/molecules/RBox'
import RAccordionItem from '@/components/library/atoms/RAccordionItem'
import RAccordion from '@/components/library/molecules/RAccordion'
import RTable from '@/components/library/organisms/RTable'
import RPagination from '@/components/library/molecules/RPagination'
import RTimeLineChart from '@/components/library/atoms/RTimeLineChart'
import RAlertCard from '@/components/library/molecules/RAlertCard'
import RNetSentimentHalfDonut from '@/components/library/molecules/RNetSentimentHalfDonut'
import RAverageStarRating from '@/components/library/molecules/RAverageStarRating'
import RStarRatingDistributionChart from '@/components/library/molecules/RStarRatingDistributionChart'
import RBigNumber from '@/components/library/molecules/RBigNumber'
import RSentimentBarChart from '@/components/library/molecules/RSentimentBarChart'
import RWordCloud from '@/components/library/organisms/RWordCloud'
import RSitesAnalyzedTable from '@/components/library/atoms/RSitesAnalyzedTable'
import RComparisonChart from '@/components/library/organisms/RComparisonChart'
import RMap from '@/components/library/atoms/RMap'
import RRatingTimeLineChart from '@/components/library/atoms/RRatingTimeLineChart'
import RTrendingEntities from '@/components/library/molecules/RTrends'
import RReviewCard from '@/components/library/atoms/RReviewCard'
import RGroupedTabs from '@/components/library/molecules/RGroupedTabs'
import RReviewCardList from '@/components/library/molecules/RReviewCardList'
import RTopicWheel from '@/components/library/molecules/RTopicWheel'
import RTopSentimentReviewCards from '@/components/library/molecules/RTopSentimentReviewCards'
import RDateRangePicker from '@/components/library/molecules/RDateRangePicker'
import RTopUrls from '@/components/library/atoms/RTopUrls'
import RRatingTimeLineComparison from '@/components/library/molecules/RRatingTimeLineComparison'
import RTextChips from '@/components/library/molecules/RTextChips'
import RSitesAnalyzed from '@/components/library/molecules/RSitesAnalyzed'
import RStackedBarChart from '@/components/library/atoms/RStackedBarChart'

import i18n from '@/translations'
import { DASHBOARD_TYPES, ALERT_TYPES, SINGLE_PRODUCT_GROUPED_TABS } from '@/utils/constants'
import {
  PIECHART_DATA,
  RSENTIMENT_BAR_CHART_SAMPLE_DATA,
  SITE_ANALYZED_TABLE_DATA,
  COMPARISON_CHART_DATA, MAP_DATA,
  RTRENDINGENTITIES_NEGATIVE_SAMPLE_DATA,
  RTRENDINGENTITIES_POSITIVE_SAMPLE_DATA,
  REVIEWCARD_DATA,
  RRATING_TIME_LINE_CHART_SAMPLE_DATA,
  REVIEWCARDLIST_DATA,
  TOPIC_WHEEL_DATA,
  REVIEWCARD_DATA_NEGATIVE,
  TOP_URL_DATA,
  RRATING_TIME_LINE_COMPARISON_SAMPLE_DATA,
  RTEXT_CHIPS_SAMPLE_DATA,
  RSTACKED_BAR_CHART_SAMPLE_DATA,
} from './sampleData'

const SENTIMENT_DISTRIBUTION_EXAMPLE = [{
  name: 'Positive',
  value: 100,
}, {
  name: 'Neutral',
  value: 200,
}, {
  name: 'Negative',
  value: 300,
}]

const RATING_DISTRIBUTION_EXAMPLE = [{
  name: 'Five',
  value: 50,
}, {
  name: 'Three',
  value: 40,
}, {
  name: 'One',
  value: 10,
}]

const availableWords = [
  'games',
  'game',
  'collection',
  'mario',
  'nintendo',
  'switch',
  'sunshine',
  'galaxy',
  'controls',
  'mario 64',
  'price',
  'fun',
  'people',
  'one',
  'graphics',
  'version',
  'way',
  'wii',
  'release',
  'gamecube',
  'super mario 64',
  'camera',
  'nostalgia',
  'super mario sunshine',
  'product',
  'package',
  'mario galaxy',
  'fan',
  'amazon',
  'classics',
  'all',
  'titles',
  'some',
  'condition',
  'stars',
  'mario sunshine',
  'childhood',
  'ports',
  'lot',
  'super mario galaxy',
  'controller',
  'kids',
  'soundtracks',
  'copy',
  'effort',
  'things',
  'n64',
  'port',
  'son',
  'cartridge',
]

export default {
  name: 'ComponentLibrary',
  components: {
    RNavigationDrawer,
    RModal,
    RButton,
    RChip,
    RSearchInput,
    RSection,
    RSectionHeader,
    RComponentHeader,
    RFileUpload,
    RDataImage,
    RDataCard,
    RPageHeader,
    RBreadcrumbs,
    RSelect,
    RAnchoredPopup,
    RTabs,
    RTabItems,
    RTabItem,
    RBarChart,
    RPieChart,
    RTextField,
    RBox,
    RAccordionItem,
    RAccordion,
    RTable,
    RPagination,
    RTimeLineChart,
    RAlertCard,
    RNetSentimentHalfDonut,
    RAverageStarRating,
    RStarRatingDistributionChart,
    RBigNumber,
    RSentimentBarChart,
    RWordCloud,
    RSitesAnalyzedTable,
    RComparisonChart,
    RMap,
    RTrendingEntities,
    RRatingTimeLineChart,
    RReviewCard,
    RGroupedTabs,
    RReviewCardList,
    RTopicWheel,
    RTopSentimentReviewCards,
    RDateRangePicker,
    RTopUrls,
    RRatingTimeLineComparison,
    RTextChips,
    RSitesAnalyzed,
    RStackedBarChart,
  },
  data: () => ({
    rRatingDistributionChartData: [
      {
        label: '5',
        value: 67,
      },
      {
        label: '4',
        value: 20,
      },
      {
        label: '3',
        value: 10,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '1',
        value: 1,
      },
    ],
    rRatingDistributionChartTitle: 'Reviews Star Rating Distribution',
    navigationItems: [],
    rButtonShowExampleModal: false,
    rSearchInputValue: '',
    fileUploadFilename: '',
    fileUploadData: '',
    PIECHART_DATA,
    SENTIMENT_DISTRIBUTION_EXAMPLE,
    RATING_DISTRIBUTION_EXAMPLE,
    DASHBOARD_TYPES,
    ALERT_TYPES,
    tab: null,
    tabItems: [
      {
        label: 'None',
        content: 'Example content of tab "None"',
        key: 'none--unique',
        icon: 'help_outline',
      },
      {
        label: 'Rating',
        content: 'Example content of tab "Rating"',
        key: 'rating--unique',
        icon: 'mdi-alpha-b-circle',

      },
      {
        label: 'Sentiment',
        content: 'Example content of tab "Sentiment"',
        key: 'sentiment--unique',
        icon: 'mdi-chevron-right',
      },
      {
        label: 'URL',
        content: 'Example content of tab "URL"',
        key: 'url--unique',
        icon: 'exit_to_app',
      },
    ],
    selectedTab: 0,
    selectedGroupTab: 0,
    selectedSubTab: 0,
    rTextInputValue: '',
    rTextFieldRules: [
      (value) => (value && value.length > 0) || 'Required.',
      (value) => (value && value.length >= 3) || 'Min 3 characters',
      (value) => (value && value.length <= 8) || 'Max 8 characters',
    ],
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'es', language: 'es', title: 'Español' },
    ],
    tableColumns: [
      {
        key: 'urls',
        label: 'URLs',
        hideable: true,
        sortable: true,
        sorted: 'asc',
        width: 100,
      },
      {
        key: 'brand',
        label: 'Brand',
        hideable: true,
        sortable: true,
      },
      {
        key: 'productCategory',
        label: 'Product category',
        hideable: true,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        align: 'right',
        width: 60,
        hideable: true,
        sortable: true,
      },
      {
        key: 'link',
        label: 'Link',
      },
      {
        key: 'creationDate',
        label: 'Creation date',
        sortable: true,
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    paginationPage: 1,
    timeLineChartData: [
      {
        label: 'Reviews',
        chartType: 'column',
        seriesData: [
          { value: 100, date: '2022-01-06' },
          { value: 300, date: '2022-02-06' },
          { value: 350, date: '2022-03-06' },
          { value: 300, date: '2022-04-06T14:33:12' },
          { value: 410, date: '2022-05-06' },
          { value: 405, date: '2022-06-06T14:33:12.701Z' },
        ],
      },
      {
        label: 'Price',
        chartType: 'spline',
        seriesData: [
          { value: 3000, date: '2022-02-06' },
          { value: 356, date: '2022-03-06' },
          { value: 3673, date: '2022-04-06T14:33:12' },
          { value: 4890, date: '2022-05-06T14:33:12.701Z' },
        ],
      },
    ],
    RSENTIMENT_BAR_CHART_SAMPLE_DATA,
    wordCloudData: availableWords.map((w) => ({
      _id: `${Math.random() * 30000000}`,
      label: w,
      volume: Math.floor(Math.random() * 10000),
      netSentiment: (Math.random() * 2) - 1,
      rating: Math.random() * 5,
    })),
    SITE_ANALYZED_TABLE_DATA,
    COMPARISON_CHART_DATA,
    MAP_DATA,
    RTRENDINGENTITIES_POSITIVE_SAMPLE_DATA,
    RTRENDINGENTITIES_NEGATIVE_SAMPLE_DATA,
    REVIEWCARD_DATA,
    REVIEWCARD_DATA_NEGATIVE,
    SINGLE_PRODUCT_GROUPED_TABS,
    RRATING_TIME_LINE_CHART_SAMPLE_DATA,
    REVIEWCARDLIST_DATA,
    TOPIC_WHEEL_DATA,
    TOP_URL_DATA,
    RRATING_TIME_LINE_COMPARISON_SAMPLE_DATA,
    RTEXT_CHIPS_SAMPLE_DATA,
    RSTACKED_BAR_CHART_SAMPLE_DATA,
  }),
  computed: {
    today() {
      return this.$moment()
        .toISOString()
    },
    tableRows() {
      const numRows = 50
      const rows = []
      for (let i = 0; i < numRows; i += 1) {
        rows.push({
          _id: `${i}-id`,
          urls: 'Samsung AU7100 55 Inch Smart TV',
          brand: 'Samsung',
          productCategory: 'Phones',
          numReviews: i,
          link: 'https://amazon.com/dp/F00BAR12',
          creationDate: new Date(`202${Math.floor(Math.random() * 3)}-12-20`),
          actions: '',
        })
      }
      return rows
    },
  },
  mounted() {
    this.navigationItems = [
      {
        title: 'ATOMS',
        children: [
          { title: 'RButton', icon: 'smart_button', ref: this.$refs.rbutton },
          { title: 'RChip', icon: 'horizontal_rule', ref: this.$refs.rchip },
          { title: 'RDataImage', icon: 'mdi-image-area', ref: this.$refs.rdataimage },
          { title: 'RBarChart', icon: 'bar_chart', ref: this.$refs.rbarchart },
          { title: 'RPieChart', icon: 'pie_chart', ref: this.$refs.rpiechart },
          { title: 'RTimeLineChart', icon: 'timeline', ref: this.$refs.rtimelinechart },
          { title: 'RSitesAnalyzedTable', icon: 'mdi-table', ref: this.$refs.rsitesanalyzedtable },
          { title: 'RMap', icon: 'mdi-map', ref: this.$refs.rmap },
          { title: 'RReviewCard', icon: 'mdi-message-draw', ref: this.$refs.rreviewcard },
          { title: 'RRatingTimeLineChart', icon: 'timeline', ref: this.$refs.rratingtimelinechart },
          { title: 'RTopUrls', icon: 'mdi-tab-search', ref: this.$refs.rtopurls },
          { title: 'RStackedBarChart', icon: 'bar_chart', ref: this.$refs.rstackedbarchart },
        ],
      },
      {
        title: 'MOLECULES',
        children: [
          { title: 'RSearchInput', icon: 'search', ref: this.$refs.rsearchinput },
          { title: 'RSectionHeader', icon: 'title', ref: this.$refs.rsectionheader },
          { title: 'RSection', icon: 'aspect_ratio', ref: this.$refs.rsection },
          { title: 'RFileUpload', icon: 'upload', ref: this.$refs.rfileupload },
          { title: 'RDataCard', icon: 'mdi-card-bulleted', ref: this.$refs.rdatacard },
          { title: 'RAlertCard', icon: 'mdi-card-bulleted', ref: this.$refs.ralertcard },
          { title: 'RPageHeader', icon: 'arrow_back', ref: this.$refs.rpageheader },
          { title: 'RBreadcrumbs', icon: 'mdi-baguette', ref: this.$refs.rbreadcrumbs },
          { title: 'RSelect', icon: 'list_alt', ref: this.$refs.rselect },
          { title: 'RAnchoredPopup', icon: 'anchor', ref: this.$refs.ranchoredpopup },
          { title: 'RTabs', icon: 'mdi-tab-search', ref: this.$refs.rtabs },
          { title: 'RTextField', icon: 'text_fields', ref: this.$refs.rtextfield },
          { title: 'RBox', icon: 'inventory', ref: this.$refs.rbox },
          { title: 'RAccordion', icon: 'unfold_more', ref: this.$refs.raccordion },
          { title: 'RPagination', icon: 'more_horiz', ref: this.$refs.rpagination },
          { title: 'RNetSentimentHalfDonut', icon: 'more_horiz', ref: this.$refs.rnetsentimenthalfdonut },
          { title: 'RAverageStarRating', icon: 'star_rate', ref: this.$refs.raveragestarrating },
          { title: 'RStarRatingDistributionChart', icon: 'star_rate', ref: this.$refs.rstarratingdistributionchart },
          { title: 'RBigNumber', icon: 'more_horiz', ref: this.$refs.rbignumber },
          { title: 'RSentimentBarChart', icon: 'bar_chart', ref: this.$refs.rsentimentbarchart },
          { title: 'RTrendingEntities', icon: 'bar_chart', ref: this.$refs.rtrendingentities },
          { title: 'RGroupedTabs', icon: 'mdi-tab-search', ref: this.$refs.rgroupedtabs },
          { title: 'RReviewCardList', icon: 'mdi-list-box-outline', ref: this.$refs.rreviewcardlist },
          { title: 'RTopicWheel', icon: 'mdi-alpha-w-circle-outline', ref: this.$refs.rtopicwheel },
          { title: 'RTopSentimentReviewCards', icon: 'mdi-tab-search', ref: this.$refs.rtopsentimentreviewcards },
          { title: 'RDateRangePicker ', icon: 'mdi-tab-search', ref: this.$refs.rdaterangepicker },
          { title: 'RRatingTimeLineComparison ', icon: 'bar_chart', ref: this.$refs.rratingtimelinecomparison },
          { title: 'RTextChips', icon: 'text_fields', ref: this.$refs.rtextchips },
          { title: 'RSitesAnalyzed', icon: 'pie_chart', ref: this.$refs.rsitesanalyzed },
        ],
      },
      {
        title: 'ORGANISMS',
        children: [
          { title: 'RModal', icon: 'launch', ref: this.$refs.rmodal },
          { title: 'RNavigationDrawer', icon: 'navigation', ref: this.$refs.rnavigationdrawer },
          { title: 'RTable', icon: 'table_chart', ref: this.$refs.rtable },
          { title: 'RWordCloud', icon: 'cloud', ref: this.$refs.rwordcloud },
          { title: 'RComparisonChart', icon: 'table_chart', ref: this.$refs.rcomparisonchart },
        ],
      },
    ]
  },
  methods: {
    toggleExampleModal() {
      this.rButtonShowExampleModal = !this.rButtonShowExampleModal
    },
    onInputChange(eventData) {
      const { value } = eventData
      this.rSearchInputValue = value
    },
    onFileUploadFile(file) {
      this.fileUploadFilename = file.name
    },
    onFileUploadData(data) {
      this.fileUploadData = data
    },
    onFileUploadUpload({ file, data }) {
      // eslint-disable-next-line no-console
      console.log({
        file,
        data,
      })
    },
    onChangeTab(selectedTabIndex) {
      this.selectedTab = selectedTabIndex
    },
    onChangeGroupTab(selectedTabIndex) {
      this.selectedSubTab = undefined
      this.selectedGroupTab = selectedTabIndex
    },
    onchangeSubTab(selectedSubTabIndex) {
      this.selectedSubTab = selectedSubTabIndex
    },
    onTextInputChange(eventData) {
      const { value } = eventData
      this.rTextInputValue = value
    },
    changeLocale(locale) {
      i18n.locale = locale
    },
    isLanguageActive(language) {
      return i18n.locale === language
    },
  },
}
</script>

<style scoped>
.componentlibrary {
  padding: 20px;
}

/deep/ .rsection {
  margin-bottom: 60px;
}

/deep/ .custom-tab-item-style {
  padding: 5px;
  margin-bottom: 10px;
}
</style>
