var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "rsitesanalyzed"
  }, [_c('v-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('r-sites-analyzed-table', {
    attrs: {
      "data": _vm.data,
      "loading": _vm.loading
    },
    on: {
      "click:hostname": _vm.onClickHostname
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm.data.length > 1 ? _c('r-pie-chart', {
    attrs: {
      "data": _vm.pieChartData,
      "loading": _vm.loading,
      "height": 350,
      "width": 350
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }