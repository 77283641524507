var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "raveragestarrating"
  }, [_c('r-component-header', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "d-flex align-center mt-4"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "raveragestarrating--number-loader",
    attrs: {
      "type": "chip"
    }
  }) : _c('div', {
    staticClass: "raveragestarrating--number-value"
  }, [_vm._v(" " + _vm._s(_vm.ratingValue) + " ")]), _c('v-divider', {
    staticClass: "divider-line",
    attrs: {
      "vertical": ""
    }
  }), _c('div', {
    staticClass: "raveragestarrating--fixed-number-value"
  }, [_vm._v(" 5 ")])], 1), !_vm.loading ? _c('r-rating', {
    staticClass: "mb-2 raveragestarrating--rating",
    attrs: {
      "value": _vm.ratingValue,
      "is-half-incrementing": _vm.isHalfIncrementing,
      "readonly": false
    },
    on: {
      "change": function change(payload) {
        return _this.$emit('click:rating', payload);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }