var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "pa-4 ma-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.messages.noResults')) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }