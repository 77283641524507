var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "raccordionitem",
    class: {
      collapsed: _vm.collapsed
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_vm.title ? _c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-icon', {
    staticClass: "toggle-icon",
    on: {
      "click": _vm.toggleCollapse
    }
  }, [_vm._v(" " + _vm._s(_vm.collapsed ? 'mdi-chevron-down' : 'mdi-chevron-up') + " ")])], 1), _c('div', {
    staticClass: "r-accordion-item--content"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }