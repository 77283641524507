var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtopicwheel"
  }, [_c('r-component-header', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "rtopicwheel--chart mx-auto"
  }, [_vm.loading ? _c('div', {
    staticClass: "d-flex align-center justify-center rtopicwheel--chart-loader"
  }, [_c('v-skeleton-loader', {
    staticClass: "rounded-circle mt-3",
    attrs: {
      "type": "image",
      "height": _vm.skeletonSize,
      "width": _vm.skeletonSize
    }
  })], 1) : _vm.data.length > 0 && !_vm.loading ? _c('highcharts', {
    attrs: {
      "options": _vm.chartOptions()
    }
  }) : _c('div', [_c('p', {
    staticClass: "rtopicwheel--no-data"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.messages.noData')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }