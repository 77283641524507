var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "componentlibrary"
  }, [_c('v-row', [_c('v-col', {
    staticStyle: {
      "max-width": "56px"
    }
  }, [_c('r-navigation-drawer', {
    staticStyle: {
      "position": "fixed",
      "z-index": "1"
    },
    attrs: {
      "title": "Component Library",
      "items": _vm.navigationItems
    }
  })], 1), _c('v-col', [_c('r-page-header', {
    staticClass: "mb-16",
    attrs: {
      "label": _vm.$t('componentLibrary.welcomeMsg')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return _vm._l(_vm.languages, function (entry) {
          return _c('r-button', {
            key: entry.title,
            staticClass: "ml-2",
            attrs: {
              "label": entry.title,
              "outlined": !_vm.isLanguageActive(entry.language)
            },
            on: {
              "click": function click($event) {
                return _vm.changeLocale(entry.language);
              }
            }
          });
        });
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rbutton",
    attrs: {
      "label": "RButton"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "mr-2",
          attrs: {
            "color": "normal",
            "label": _vm.$t('global.actions.search'),
            "icon": "search"
          }
        }), _c('r-button', {
          attrs: {
            "type": "normal",
            "label": _vm.$t('global.actions.search'),
            "icon": "",
            "outlined": true
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rchip",
    attrs: {
      "label": "RChip"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-chip-group', [_c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 1
            }),
            "removable": ""
          }
        }), _c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 2
            }),
            "removable": ""
          }
        }), _c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 3
            }),
            "removable": ""
          }
        })], 1), _c('v-chip-group', [_c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 1
            }),
            "textonly": ""
          }
        }), _c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 2
            }),
            "textonly": "",
            "outlined": ""
          }
        }), _c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 3
            }),
            "textonly": ""
          }
        })], 1), _c('v-chip-group', [_c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 1
            }),
            "width": 50
          }
        }), _c('r-chip', {
          attrs: {
            "label": _vm.$t('componentLibrary.filter', {
              num: 1
            }),
            "width": 187
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rmodal",
    attrs: {
      "label": "RModal"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-button', {
          ref: "rmodal-trigger",
          attrs: {
            "type": "primary",
            "label": _vm.$t('componentLibrary.openModal')
          },
          on: {
            "click": _vm.toggleExampleModal
          }
        }), _vm.rButtonShowExampleModal ? _c('r-modal', {
          attrs: {
            "title": "Example Modal",
            "close-action-label": _vm.$t('global.actions.cancel'),
            "action-label": _vm.$t('componentLibrary.submit'),
            "alternate-action-label": _vm.$t('componentLibrary.alternateSubmit'),
            "fetching": false,
            "action-completed": false
          },
          on: {
            "submit": _vm.toggleExampleModal,
            "submit:alternate": _vm.toggleExampleModal,
            "close": _vm.toggleExampleModal
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function fn() {
              return [_c('div', [_c('label', [_vm._v("An static content")])])];
            },
            proxy: true
          }], null, false, 2648655895)
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsearchinput",
    attrs: {
      "label": "RSearchInput"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-search-input', {
          attrs: {
            "placeholder": _vm.$t('componentLibrary.searchResource', {
              resource: _vm.$t('resources.product')
            }),
            "value": _vm.rSearchInputValue
          },
          on: {
            "change": _vm.onInputChange
          }
        }), _c('p', [_vm._v(" " + _vm._s(_vm.$t('componentLibrary.valueIs')) + ": " + _vm._s(_vm.rSearchInputValue) + " ")])];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsectionheader",
    attrs: {
      "label": "RComponentHeader"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-component-header', [_vm._v(" " + _vm._s(_vm.$t('componentLibrary.exampleSectionHeader')) + " ")])];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsectionheader",
    attrs: {
      "label": "RSectionHeader"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-section-header', {
          attrs: {
            "label": _vm.$t('componentLibrary.exampleSectionHeader')
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsection",
    attrs: {
      "label": "RSection"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-section', {
          attrs: {
            "label": "Buttons Components",
            "collapsable": true
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function fn() {
              return [_c('div', [_c('p', [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pharetra malesuada velit, non venenatis velit vestibulum vitae. Fusce eget pretium arcu. Morbi tincidunt scelerisque ante et sodales. Donec eget purus eget enim hendrerit dignissim. Nullam et pellentesque felis. Duis mollis non augue ac pulvinar. Aliquam at mi non turpis dignissim gravida. Donec pharetra vitae mauris nec tristique. ")]), _c('p', [_vm._v(" Vivamus risus nulla, laoreet sit amet nisi sit amet, bibendum hendrerit urna. Donec sit amet laoreet arcu. Ut tempus metus nec nisl luctus mattis. Donec efficitur nisl id lectus efficitur, a vestibulum tellus scelerisque. Morbi dolor turpis, aliquam eu fermentum vitae, molestie vitae nisl. Donec augue purus, fermentum quis suscipit eu, accumsan eu orci. Nunc libero orci, sollicitudin eget accumsan in, molestie ut leo. Quisque vestibulum accumsan risus, quis bibendum magna interdum eu. Nunc euismod mauris et laoreet maximus. Etiam dui tellus, lacinia in posuere id, fringilla euismod mauris. Ut non dictum metus. Quisque malesuada nibh interdum mi tempor vestibulum. Nulla pellentesque vehicula euismod. Curabitur sollicitudin efficitur efficitur. In non luctus metus. Nam tempor luctus lobortis. ")])])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rfileupload",
    attrs: {
      "label": "RFileUpload"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-file-upload', {
          attrs: {
            "label": _vm.$t('componentLibrary.uploadFileLabel'),
            "placeholder": _vm.$t('componentLibrary.uploadFilePlaceholder'),
            "filetypes": ['.txt'],
            "select-file-label": _vm.$t('componentLibrary.uploadFileSelectLabel'),
            "upload-button-label": _vm.$t('componentLibrary.uploadButtonLabel')
          },
          on: {
            "file": _vm.onFileUploadFile,
            "data": _vm.onFileUploadData,
            "upload": _vm.onFileUploadUpload
          }
        }), _c('p', [_vm._v(" Uploaded file name: " + _vm._s(_vm.fileUploadFilename) + " ")]), _c('p', [_vm._v(" Uploaded file data length: " + _vm._s(_vm.fileUploadData.length) + " ")])];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rdataimage",
    attrs: {
      "label": "RDataImage"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Valid url ")]), _c('r-data-image', {
          attrs: {
            "ids": ['https://i.picsum.photos/id/732/510/300.jpg?hmac=QZJQGU9iS24mIgx_75CDQbfzjceydJS5mHNEUXVampE']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Invalid URL ")]), _c('r-data-image', {
          attrs: {
            "ids": ['https://bad.src/not/valid']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" 404 URL ")]), _c('r-data-image', {
          attrs: {
            "ids": ['https://i.picsum.photos/id/000/510/300.jpg?hmac=']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Product ")]), _c('r-data-image', {
          attrs: {
            "type": _vm.DASHBOARD_TYPES.PRODUCT,
            "ids": ['https://picsum.photos/510/300?random']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Tag ")]), _c('r-data-image', {
          attrs: {
            "type": _vm.DASHBOARD_TYPES.TAG,
            "ids": ['https://picsum.photos/500/300?random']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Dashboard ")]), _c('r-data-image', {
          attrs: {
            "type": _vm.DASHBOARD_TYPES.DASHBOARD,
            "ids": ['https://picsum.photos/520/300?random', 'https://i.picsum.photos/id/586/520/300.jpg?hmac=qRtbjEnAtlrcWaJ7O4XyUXa1h50ATDi8TA6QvOd59IE']
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('p', [_vm._v(" Dashboard ")]), _c('r-data-image', {
          attrs: {
            "type": _vm.DASHBOARD_TYPES.DASHBOARD,
            "ids": ['https://i.picsum.photos/id/568/520/300.jpg?hmac=CdChp0Y6odWjIYMCjGm_PCto5_eJPkwI8DGxmaLzV6w', 'https://i.picsum.photos/id/586/520/300.jpg?hmac=qRtbjEnAtlrcWaJ7O4XyUXa1h50ATDi8TA6QvOd59IE', 'https://i.picsum.photos/id/534/520/300.jpg?hmac=hxHVPbOozUzmNL9XRmGp-qXMmWK6dSwWCJHhJ5jWAcU']
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rdatacard",
    attrs: {
      "label": "RDataCard"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('p', [_vm._v(" Product Card ")]), _c('r-data-card', {
          attrs: {
            "id": "test-2",
            "name": "Nutella",
            "type": _vm.DASHBOARD_TYPES.PRODUCT,
            "bookmark": true,
            "num-products": 1,
            "num-reviews": 1,
            "num-urls": 1,
            "rating": 2,
            "sentiment-distribution": _vm.SENTIMENT_DISTRIBUTION_EXAMPLE,
            "progress-percent": 70,
            "last-update": "2022-03-30T00:00:00.218Z",
            "modified": "2022-03-30T00:00:00.218Z",
            "bcr-link-id": "bcr-link-id"
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('p', [_vm._v(" Tag Cards ")]), _c('r-data-card', {
          attrs: {
            "id": "test-3",
            "name": "Chocolate spreads",
            "type": _vm.DASHBOARD_TYPES.TAG,
            "num-products": 12,
            "num-reviews": 12,
            "num-urls": 11,
            "bookmark": true,
            "rating-distribution": _vm.RATING_DISTRIBUTION_EXAMPLE,
            "last-update": "2022-03-28T00:00:00.218Z",
            "modified": "2022-03-30T00:00:00.218Z"
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('p', [_vm._v(" Dashboard Card ")]), _c('r-data-card', {
          attrs: {
            "id": "test-4",
            "type": _vm.DASHBOARD_TYPES.DASHBOARD,
            "name": "Nutella vs Chocolate Spreads",
            "num-products": 11,
            "num-reviews": 12,
            "num-urls": 13,
            "last-update": _vm.today,
            "rating-distribution": _vm.RATING_DISTRIBUTION_EXAMPLE,
            "modified": "2022-03-30T00:00:00.218Z"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "ralertcard",
    attrs: {
      "label": "RAlertCard"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('r-alert-card', {
          attrs: {
            "type": _vm.ALERT_TYPES.URL_ERROR,
            "date": "2022-03-30T00:00:00.218Z",
            "data": {}
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('r-alert-card', {
          attrs: {
            "data": {
              searchName: 'TVs',
              newFounds: 45
            },
            "type": _vm.ALERT_TYPES.SEARCH_NEW_FOUNDS,
            "date": "2022-03-30T00:00:00.218Z"
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('r-alert-card', {
          attrs: {
            "type": _vm.ALERT_TYPES.NEW_PRODUCT,
            "data": {
              uploadName: 'Electronics',
              numProduct: 1
            },
            "date": "2022-03-30T00:00:00.218Z"
          }
        })], 1), _c('v-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('r-alert-card', {
          attrs: {
            "type": _vm.ALERT_TYPES.UPLOAD_COMPLETED,
            "data": {
              uploadName: 'Electronics'
            },
            "date": "2022-03-30T00:00:00.218Z"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rpageheader",
    attrs: {
      "label": "RPageHeader"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-page-header', {
          attrs: {
            "label": "A page header"
          }
        }), _c('r-page-header', {
          attrs: {
            "label": "With a back button",
            "previousroute": "/"
          }
        }), _c('r-page-header', {
          attrs: {
            "label": "With a postlabel slot"
          },
          scopedSlots: _vm._u([{
            key: "postlabel",
            fn: function fn() {
              return [_c('r-button', {
                attrs: {
                  "label": _vm.$t('global.actions.save')
                }
              })];
            },
            proxy: true
          }])
        }), _c('r-page-header', {
          attrs: {
            "label": "With an end slot"
          },
          scopedSlots: _vm._u([{
            key: "end",
            fn: function fn() {
              return [_c('r-search-input', {
                staticStyle: {
                  "max-width": "200px"
                },
                attrs: {
                  "placeholder": "Search something"
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rbreadcrumbs",
    attrs: {
      "label": "RBreadcrumbs"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-breadcrumbs', {
          attrs: {
            "items": [{
              label: 'Home',
              routePath: '/'
            }, {
              label: 'Dashboards',
              routePath: '/dashboards'
            }, {
              label: 'Components',
              routePath: '/components'
            }]
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rselect",
    attrs: {
      "label": "RSelect"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-select', {
          attrs: {
            "placeholder": "Single select",
            "items": [{
              label: 'hello',
              value: 'foo',
              selected: false
            }, {
              label: 'hi!',
              value: 'bar',
              selected: false
            }]
          }
        }), _c('r-select', {
          attrs: {
            "placeholder": "Single select with a selected value",
            "items": [{
              label: 'hello',
              value: 'foo2',
              selected: false
            }, {
              label: 'hi!',
              value: 'bar2',
              selected: true
            }]
          }
        }), _c('r-select', {
          attrs: {
            "placeholder": "Multiple select",
            "multiple": "",
            "items": [{
              label: 'hello',
              value: 'foo3',
              selected: false
            }, {
              label: 'hi!',
              value: 'bar3',
              selected: false
            }, {
              label: 'good day!',
              value: 'bish3',
              selected: false
            }, {
              label: 'Bonjour',
              value: 'bosh3',
              selected: false
            }, {
              label: 'Kalispera',
              value: 'bash3',
              selected: false
            }]
          }
        }), _c('r-select', {
          attrs: {
            "placeholder": "Multiple select with selected values",
            "multiple": "",
            "items": [{
              label: 'hello',
              value: 'foo4',
              selected: true
            }, {
              label: 'hi!',
              value: 'bar4',
              selected: false
            }, {
              label: 'good day!',
              value: 'bish4',
              selected: true
            }, {
              label: 'Bonjour',
              value: 'bosh4',
              selected: false
            }, {
              label: 'Kalispera',
              value: 'bash4',
              selected: false
            }]
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "ranchoredpopup",
    attrs: {
      "label": "RAnchoredPopup"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-anchored-popup', {
          attrs: {
            "label": "Filters",
            "icon": "filter_list",
            "anchor": "top-right"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var onClose = _ref.onClose;
              return [_c('v-card-text', [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel quibusdam reprehenderit, aliquid recusandae perferendis ut eaque velit neque quis nam voluptas voluptatem debitis dignissimos, cumque eveniet id animi consequuntur quae? ")]), _c('v-card-actions', {
                staticClass: "justify-end"
              }, [_c('r-button', {
                attrs: {
                  "label": "cancel",
                  "type": "normal"
                },
                on: {
                  "click": onClose
                }
              }), _c('r-button', {
                attrs: {
                  "label": "apply"
                },
                on: {
                  "click": onClose
                }
              })], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtabs",
    attrs: {
      "label": "RTabs"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-tabs', {
          attrs: {
            "items": _vm.tabItems,
            "selected-tab": _vm.selectedTab
          },
          on: {
            "change": _vm.onChangeTab
          }
        }), _c('r-tab-items', {
          attrs: {
            "selected-tab": _vm.selectedTab
          }
        }, [_vm._t("default", function () {
          return _vm._l(_vm.tabItems, function (item) {
            return _c('r-tab-item', {
              key: item.label,
              attrs: {
                "label": item.label
              }
            }, [_vm._t("default", function () {
              return [_c('div', {
                staticClass: "custom-tab-item-style"
              }, [_c('label', [_vm._v(_vm._s(item.content))])])];
            })], 2);
          });
        })], 2), _c('r-section-header', {
          attrs: {
            "label": "Entity progress",
            "collapsable": false
          },
          scopedSlots: _vm._u([{
            key: "postlabel",
            fn: function fn() {
              return [_c('r-tabs', {
                attrs: {
                  "items": _vm.tabItems,
                  "selected-tab": _vm.selectedTab
                },
                on: {
                  "change": _vm.onChangeTab
                }
              })];
            },
            proxy: true
          }])
        }), _c('r-tab-items', {
          attrs: {
            "selected-tab": _vm.selectedTab
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return _vm._l(_vm.tabItems, function (item) {
                return _c('r-tab-item', {
                  key: item.label,
                  attrs: {
                    "label": item.label
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn() {
                      return [_c('label', [_vm._v(_vm._s(item.content))])];
                    },
                    proxy: true
                  }], null, true)
                });
              });
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }], null, true)
  }), _c('r-section', {
    ref: "rnavigationdrawer",
    attrs: {
      "label": "RNavigationDrawer"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-navigation-drawer', {
          staticStyle: {
            "height": "200px"
          },
          attrs: {
            "title": "Test navigation",
            "items": [{
              title: 'Hello',
              icon: 'grade',
              ref: 'testRef'
            }, {
              title: 'Hi!',
              icon: 'bolt',
              ref: 'testRef2'
            }]
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rbarchart",
    attrs: {
      "label": "RBarChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('p', [_vm._v("Horizontal Chart")]), _c('r-bar-chart', {
          attrs: {
            "data": [{
              value: 3
            }, {
              value: 20
            }, {
              value: 50
            }],
            "vertical": false
          }
        })], 1), _c('v-col', [_c('p', [_vm._v("Vertical Chat")]), _c('r-bar-chart', {
          attrs: {
            "data": [{
              value: 3
            }, {
              value: 20
            }, {
              value: 50
            }]
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rpiechart",
    attrs: {
      "label": "RPieChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-pie-chart', {
          attrs: {
            "data": _vm.PIECHART_DATA
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rpagination",
    attrs: {
      "label": "RPagination"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-pagination', {
          attrs: {
            "page": _vm.paginationPage,
            "total-pages": 3456
          },
          on: {
            "click:page": function clickPage(_ref2) {
              var value = _ref2.value;
              return _vm.paginationPage = value;
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtable",
    attrs: {
      "label": "RTable"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-table', {
          key: "exampletables",
          attrs: {
            "columns": _vm.tableColumns,
            "rows": _vm.tableRows,
            "total-rows": _vm.tableRows.length,
            "height": 300
          },
          scopedSlots: _vm._u([{
            key: "item.urls",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('div', {
                staticClass: "text-truncate",
                staticStyle: {
                  "width": "100px"
                }
              }, [_c('a', {
                attrs: {
                  "href": "#"
                }
              }, [_vm._v(_vm._s(item.urls))])])];
            }
          }, {
            key: "item.link",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('r-chip', {
                attrs: {
                  "label": item.link
                }
              })];
            }
          }, {
            key: "item.creationDate",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(item.creationDate.getDate()) + "." + _vm._s(item.creationDate.getMonth() + 1) + "." + _vm._s(item.creationDate.getFullYear()) + " ")];
            }
          }, {
            key: "item.actions",
            fn: function fn() {
              return [_c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" cloud_upload ")])], 1), _c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" edit ")])], 1), _c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" delete ")])], 1)];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtextfield",
    attrs: {
      "label": "RTextField"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('r-text-field', {
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "label": "Text field",
            "placeholder": "Eg name"
          },
          on: {
            "change": _vm.onTextInputChange
          }
        }), _c('p', [_vm._v(" " + _vm._s(_vm.$t('componentLibrary.valueIs')) + ": " + _vm._s(_vm.rTextInputValue) + " ")])], 1), _c('v-col', [_c('r-text-field', {
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "label": "Text field with rules applied",
            "placeholder": "Eg name",
            "rules": _vm.rTextFieldRules
          },
          on: {
            "change": _vm.onTextInputChange
          }
        }), _c('p', [_vm._v(" " + _vm._s(_vm.$t('componentLibrary.valueIs')) + ": " + _vm._s(_vm.rTextInputValue) + " ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rbox",
    attrs: {
      "label": "RBox"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('r-box', {
          attrs: {
            "title": "URL details"
          }
        }, [_c('p', [_c('b', [_vm._v("Last edited:")]), _vm._v(" 20.12.2022 ")]), _c('p', [_c('b', [_vm._v("Creation date:")]), _vm._v(" 20.12.2022 ")])])], 1), _c('v-col', [_c('r-box', [_c('r-bar-chart', {
          attrs: {
            "data": [{
              value: 3
            }, {
              value: 20
            }, {
              value: 50
            }],
            "vertical": false
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "raccordion",
    attrs: {
      "label": "RAccordion"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('p', [_vm._v("First item opened:")]), _c('r-accordion', {
          attrs: {
            "active-item": 0
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var activeItem = _ref6.activeItem,
                  onChangeActive = _ref6.onChangeActive;
              return _vm._l(3, function (item, i) {
                return _c('r-accordion-item', {
                  key: i,
                  attrs: {
                    "item-index": i,
                    "collapsed": activeItem !== i,
                    "title": "Item".concat(++i)
                  },
                  on: {
                    "change": onChangeActive
                  }
                }, [_c('p', [_vm._v(" Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, porro. Non a excepturi, voluptatibus ipsam magnam, eligendi, accusantium ipsa quae quis praesentium voluptate saepe ullam sint ea itaque consectetur impedit? ")])]);
              });
            }
          }])
        })], 1), _c('v-col', [_c('p', [_vm._v("All items closed:")]), _c('r-accordion', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var activeItem = _ref7.activeItem,
                  onChangeActive = _ref7.onChangeActive;
              return _vm._l(3, function (item, i) {
                return _c('r-accordion-item', {
                  key: i,
                  attrs: {
                    "item-index": i,
                    "collapsed": activeItem !== i,
                    "title": "Item".concat(++i)
                  },
                  on: {
                    "change": onChangeActive
                  }
                }, [_c('p', [_vm._v(" Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, porro. Non a excepturi, voluptatibus ipsam magnam, eligendi, accusantium ipsa quae quis praesentium voluptate saepe ullam sint ea itaque consectetur impedit? ")])]);
              });
            }
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "raveragestarrating",
    attrs: {
      "label": "RAverageStarRating"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-average-star-rating', {
          attrs: {
            "value": 3.5,
            "title": _vm.$t('averageStarRating.title')
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rbignumber",
    attrs: {
      "label": "RBigNumber"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "r-big-number",
          attrs: {
            "md": "3"
          }
        }, [_c('r-big-number', {
          attrs: {
            "value": 4936100,
            "title": _vm.$t('bigNumber.totalNumberOfReviews'),
            "previous-value": 4740
          }
        })], 1), _c('v-col', {
          staticClass: "r-big-number",
          attrs: {
            "md": "3"
          }
        }, [_c('r-big-number', {
          attrs: {
            "value": 9613050,
            "title": _vm.$t('bigNumber.totalNumberOfReviews'),
            "previous-value": 7500
          }
        })], 1), _c('v-col', {
          staticClass: "r-big-number",
          attrs: {
            "md": "3"
          }
        }, [_c('r-big-number', {
          attrs: {
            "value": 313050,
            "title": _vm.$t('bigNumber.totalNumberOfReviews'),
            "previous-value": 3687
          }
        })], 1), _c('v-col', {
          staticClass: "r-big-number",
          attrs: {
            "md": "3"
          }
        }, [_c('r-big-number', {
          attrs: {
            "value": 84443050,
            "title": _vm.$t('bigNumber.totalNumberOfReviews'),
            "previous-value": 220
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtimelinechart",
    attrs: {
      "collapsable": "",
      "label": "RTimeLineChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('p', [_vm._v("Line chart:")]), _c('r-time-line-chart', {
          attrs: {
            "data": [_vm.timeLineChartData[0]]
          }
        })], 1), _c('v-col', [_c('p', [_vm._v("Spline chart:")]), _c('r-time-line-chart', {
          attrs: {
            "spline": "",
            "data": [_vm.timeLineChartData[0]]
          }
        })], 1), _c('v-col', [_c('p', [_vm._v("Chart with two series:")]), _c('r-time-line-chart', {
          attrs: {
            "data": _vm.timeLineChartData
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rstarratingdistributionchart",
    attrs: {
      "collapsable": "",
      "label": "RStarRatingDistributionChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('p', [_vm._v("Star Rating Distribution Chart:")]), _c('r-star-rating-distribution-chart', {
          attrs: {
            "title": _vm.rRatingDistributionChartTitle,
            "data": _vm.rRatingDistributionChartData
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rnetsentimenthalfdonut",
    attrs: {
      "collapsable": "",
      "label": "RNetSentimentHalfDonut"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('r-net-sentiment-half-donut', {
          attrs: {
            "title": "Net sentiment distribution (Vertical)",
            "net-sentiment": 0.6,
            "num-negative": 2,
            "num-neutral": 3,
            "num-positive": 10
          }
        })], 1), _c('v-col', [_c('div', {
          staticStyle: {
            "width": "250px",
            "height": "118px"
          }
        }, [_c('r-net-sentiment-half-donut', {
          attrs: {
            "title": "Net sentiment distribution (Horizontal)",
            "horizontal": "",
            "net-sentiment": 0.6,
            "num-negative": 2,
            "num-neutral": 3,
            "num-positive": 10
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsentimentbarchart",
    attrs: {
      "collapsable": "",
      "label": "Rsentimentbarchart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-container', [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('r-sentiment-bar-chart', {
          attrs: {
            "data": _vm.RSENTIMENT_BAR_CHART_SAMPLE_DATA
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rwordcloud",
    attrs: {
      "collapsable": "",
      "label": "RWordCloud"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-word-cloud', {
          attrs: {
            "entities": _vm.wordCloudData
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtrendingentities",
    attrs: {
      "collapsable": "",
      "label": "RTrendingEntities"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-container', [_c('v-col', [_c('r-trending-entities', {
          attrs: {
            "total-positive": 2117,
            "total-negative": 1172,
            "positive-percent-increase": 6,
            "negative-percent-increase": 7,
            "positive-data": _vm.RTRENDINGENTITIES_POSITIVE_SAMPLE_DATA,
            "negative-data": _vm.RTRENDINGENTITIES_NEGATIVE_SAMPLE_DATA,
            "positive-entities": ['speakers', 'quality', 'price'],
            "negative-entities": ['speakers', 'quality', 'price']
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsitesanalyzedtable",
    attrs: {
      "collapsable": "",
      "label": "RSitesAnalyzedTable"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-sites-analyzed-table', {
          attrs: {
            "data": _vm.SITE_ANALYZED_TABLE_DATA,
            "loading": false
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rcomparisonchart",
    attrs: {
      "collapsable": "",
      "label": "RComparisonChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-comparison-chart', {
          attrs: {
            "data": _vm.COMPARISON_CHART_DATA.data,
            "breakdown-keys": _vm.COMPARISON_CHART_DATA.breakdownKeys
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rmap",
    attrs: {
      "collapsable": "",
      "label": "RMap"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-map', {
          attrs: {
            "data": _vm.MAP_DATA
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rratingtimelinechart",
    attrs: {
      "collapsable": "",
      "label": "RRatingTimeLineChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('r-rating-time-line-chart', {
          attrs: {
            "title": _vm.$t('dashboards.components.averageStarRatingOverTime'),
            "data": _vm.RRATING_TIME_LINE_CHART_SAMPLE_DATA,
            "max-rate-value": 5,
            "rate-value": 4.3,
            "loading": false
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rreviewcard",
    attrs: {
      "collapsable": "",
      "label": "RReviewCard"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-review-card', {
          attrs: {
            "search": _vm.REVIEWCARD_DATA.search,
            "avatar-url": _vm.REVIEWCARD_DATA.avatarUrl,
            "username": _vm.REVIEWCARD_DATA.username,
            "date": _vm.REVIEWCARD_DATA.date,
            "url": _vm.REVIEWCARD_DATA.url,
            "rating": _vm.REVIEWCARD_DATA.rating,
            "title": _vm.REVIEWCARD_DATA.title,
            "original-title": _vm.REVIEWCARD_DATA.originalTitle,
            "review-id": _vm.REVIEWCARD_DATA.reviewId,
            "sentiment": _vm.REVIEWCARD_DATA.sentiment,
            "language": _vm.REVIEWCARD_DATA.language,
            "text": _vm.REVIEWCARD_DATA.text,
            "original-text": _vm.REVIEWCARD_DATA.originalText,
            "entities": _vm.REVIEWCARD_DATA.entities
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rgroupedtabs",
    attrs: {
      "collapsable": "",
      "label": "RGroupedTabs"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-grouped-tabs', {
          ref: "rgroupedtabs",
          attrs: {
            "selected-group-tab": _vm.selectedGroupTab,
            "selected-sub-tab": _vm.selectedSubTab,
            "items": _vm.SINGLE_PRODUCT_GROUPED_TABS
          },
          on: {
            "change": _vm.onChangeGroupTab,
            "change:sub-tab": _vm.onchangeSubTab
          }
        }), _vm.selectedSubTab !== undefined ? _c('v-tabs-items', {
          attrs: {
            "value": _vm.selectedSubTab
          }
        }, _vm._l(_vm.SINGLE_PRODUCT_GROUPED_TABS[_vm.selectedGroupTab]['items'], function (i) {
          return _c('v-tab-item', {
            key: i.key
          }, [_vm._v(" " + _vm._s(i.content) + " ")]);
        }), 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rreviewcardlist",
    attrs: {
      "collapsable": "",
      "label": "RReviewCardList"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-review-card-list', {
          attrs: {
            "reviews": _vm.REVIEWCARDLIST_DATA.reviews,
            "total-reviews": _vm.REVIEWCARDLIST_DATA.totalReviews,
            "page": _vm.REVIEWCARDLIST_DATA.page,
            "page-size": _vm.REVIEWCARDLIST_DATA.pageSize,
            "total-pages": _vm.REVIEWCARDLIST_DATA.totalPages
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtopicwheel",
    attrs: {
      "collapsable": "",
      "label": "RTopicWheel"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', {
          staticStyle: {
            "height": "600px"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('r-topic-wheel', {
          attrs: {
            "title": "Topic Wheel (Flat)",
            "data": _vm.TOPIC_WHEEL_DATA
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('r-topic-wheel', {
          attrs: {
            "title": "Topic Wheel (Hierarchical)",
            "data": _vm.TOPIC_WHEEL_DATA,
            "hierarchical": ""
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtopsentimentreviewcards",
    attrs: {
      "collapsable": "",
      "label": "RTopSentimentReviewCards"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-top-sentiment-review-cards', {
          ref: "rtopsentimentreviewcards",
          attrs: {
            "positive-review": _vm.REVIEWCARD_DATA,
            "negative-review": _vm.REVIEWCARD_DATA_NEGATIVE
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtopurls",
    attrs: {
      "collapsable": "",
      "label": "RTopUrls"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-top-urls', {
          ref: "rtopurls",
          attrs: {
            "highest-rated": _vm.TOP_URL_DATA.highestRated,
            "most-reviewed": _vm.TOP_URL_DATA.mostReviewed
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rdaterangepicker",
    attrs: {
      "collapsable": "",
      "label": "RDateRangePicker"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-date-range-picker', {
          ref: "rdaterangepicker",
          staticStyle: {
            "position": "static"
          },
          attrs: {
            "range": ['2022-09-05', '2022-09-07'],
            "quick-selections": [{
              label: 'Today',
              value: {
                startDate: '2022-09-05',
                endDate: '2022-09-05'
              }
            }, {
              label: '7 days',
              value: {
                startDate: '2022-08-30',
                endDate: '2022-09-05'
              }
            }, {
              label: '14 days',
              value: {
                startDate: '2022-08-23',
                endDate: '2022-09-05'
              }
            }, {
              label: '1 month',
              value: {
                startDate: '2022-08-07',
                endDate: '2022-09-05'
              }
            }]
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rratingtimelinecomparison",
    attrs: {
      "collapsable": "",
      "label": "RRatingTimeLineComparison"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-rating-time-line-comparison', {
          ref: "rratingtimelinecomparison",
          attrs: {
            "data": _vm.RRATING_TIME_LINE_COMPARISON_SAMPLE_DATA
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rtextchips",
    attrs: {
      "collapsable": "",
      "label": "RTextChips"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-text-chips', {
          attrs: {
            "chips": _vm.RTEXT_CHIPS_SAMPLE_DATA
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rsitesanalyzed",
    attrs: {
      "collapsable": "",
      "label": "RSitesAnalyzed"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("One item in the table (hide the pie chart):")]), _c('r-sites-analyzed', {
          attrs: {
            "data": _vm.SITE_ANALYZED_TABLE_DATA.slice(0, 1)
          }
        }), _c('br'), _c('br'), _c('p', [_vm._v("More than one item in the table (show the pie chart):")]), _c('r-sites-analyzed', {
          attrs: {
            "data": _vm.SITE_ANALYZED_TABLE_DATA.slice(0, 3)
          }
        })];
      },
      proxy: true
    }])
  }), _c('r-section', {
    ref: "rstackedbarchart",
    attrs: {
      "collapsable": "",
      "label": "RStackedBarChart"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-stacked-bar-chart', {
          attrs: {
            "properties": _vm.RSTACKED_BAR_CHART_SAMPLE_DATA.properties,
            "data": _vm.RSTACKED_BAR_CHART_SAMPLE_DATA.data
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }