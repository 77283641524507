var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rbignumber"
  }, [_c('r-component-header', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "d-flex align-center mt-4"
  }, [_c('div', {
    staticClass: "d-flex box"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "rbignumber--loader",
    attrs: {
      "type": "chip"
    }
  }) : _c('div', {
    staticClass: "rbignumber--short-number",
    attrs: {
      "title": _vm.value
    }
  }, [_vm._v(" " + _vm._s(_vm.shortNumber) + " ")]), _vm.previousValue ? [_c('v-divider', {
    staticClass: "divider-line",
    attrs: {
      "vertical": ""
    }
  }), _vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "chip"
    }
  }) : _c('div', [_c('v-icon', {
    class: _vm.getIconName.replace('_', '-')
  }, [_vm._v(" " + _vm._s(_vm.getIconName) + " ")]), _c('div', {
    staticClass: "rbignumber--previous-period-value",
    attrs: {
      "title": _vm.previousValue
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('bigNumber.previousPeriod')) + ": " + _vm._s(_vm.shortPreviousValue) + " ")])], 1)] : _vm._e()], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }