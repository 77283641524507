var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rpiechart"
  }, [_vm.loading ? _c('div', [_c('v-skeleton-loader', {
    staticClass: "rpiechart--loader rounded-circle elevation-0",
    attrs: {
      "type": "image",
      "height": _vm.height,
      "width": _vm.width
    }
  })], 1) : _c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }