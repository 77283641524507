var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rcomponentheader"
  }, [_vm._t("default"), _c('div', {
    staticClass: "rcomponentheader--end"
  }, [_vm._t("end")], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }