var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "elevation-0 rsatable--loader",
    attrs: {
      "type": "table-thead, table-tbody",
      "height": "322"
    }
  }) : _c('table', {
    staticClass: "rsatable"
  }, [_c('thead', {
    staticClass: "rsatable--header"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('sitesAnalyzedTable.siteAnalysis')))]), _c('th', [_vm._v(_vm._s(_vm.$t('sitesAnalyzedTable.reviews')))]), _c('th', [_vm._v(_vm._s(_vm.$t('sitesAnalyzedTable.netSentiment')))]), _c('th', [_vm._v(_vm._s(_vm.$t('sitesAnalyzedTable.averageRating')))])])]), _c('tbody', {
    staticClass: "rsatable--body"
  }, _vm._l(_vm.data, function (entry, i) {
    return _c('tr', {
      key: entry.hostname,
      staticClass: "clickable",
      on: {
        "click": function click($event) {
          return _vm.onClick(entry.hostname);
        }
      }
    }, [_c('td', {
      staticClass: "rsatable--hostname d-flex"
    }, [_c('span', {
      staticClass: "color-circle mr-2",
      style: {
        backgroundColor: _vm.getColorForNumber(i)
      }
    }), _c('span', {
      staticClass: "hostname"
    }, [_vm._v(" " + _vm._s(entry.hostname) + " ")])]), _c('td', [_vm._v(_vm._s(entry.numReviews))]), _c('td', {
      staticClass: "rsatable--net-sentiment",
      style: {
        color: _vm.getSentimentColor(entry.netSentiment)
      }
    }, [_vm._v(" " + _vm._s(_vm.formatNumber(entry.netSentiment)) + " ")]), _c('td', [_c('v-icon', {
      staticClass: "rsatable--star"
    }, [_vm._v(" mdi-star ")]), _vm._v(" " + _vm._s(_vm.formatNumber(entry.averageRating)) + " ")], 1)]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }