var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-testid": "rbarchart"
    }
  }, [_c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }