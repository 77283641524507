var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rratingtimelinecomparison"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mr-5",
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center flex-wrap"
  }, [_c('h3', [_vm._v("Star rating")]), _c('r-select', {
    staticClass: "rratingtimelinecomparison--selector",
    attrs: {
      "show-search": false,
      "items": _vm.starRatings
    },
    on: {
      "change": _vm.onSortChange
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center flex-wrap"
  }, [_c('h3', [_vm._v("In the last")]), _c('r-select', {
    staticClass: "rratingtimelinecomparison--selector",
    attrs: {
      "show-search": false,
      "items": _vm.daysFilter
    },
    on: {
      "change": _vm.onDayFilterChange
    }
  })], 1)])], 1), _c('v-row', [_vm.series.length ? _c('v-col', {
    staticClass: "rratingtimelinecomparison--chart-col"
  }, [_c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1) : _vm._e(), !_vm.series.length ? _c('r-no-results-message') : _vm._e(), _vm.seriesForTable.length ? _c('v-col', {
    staticClass: "rratingtimelinecomparison--table-col"
  }, _vm._l(_vm.seriesForTable, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "d-flex rratingtimelinecomparison--table-row"
    }, [_c('div', {
      staticClass: "rratingtimelinecomparison--product-color"
    }, [_c('div', {
      style: {
        'background-color': item.color
      }
    })]), _c('div', {
      staticClass: "rratingtimelinecomparison--product"
    }, [_c('r-data-image', {
      staticClass: "rratingtimelinecomparison--product-img",
      attrs: {
        "type": "url",
        "ids": [item._id],
        "size": 32
      }
    }), _c('span', {
      staticClass: "rratingtimelinecomparison--product-name text-truncate"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('div', {
      staticClass: "rratingtimelinecomparison--rating"
    }, [_c('span', {
      staticClass: "rratingtimelinecomparison--rating-number"
    }, [_vm._v(" " + _vm._s(item.data[item.data.length - 1][1].toPrecision(2)) + " ")]), _c('img', {
      staticClass: "rratingtimelinecomparison--rating-star",
      attrs: {
        "src": require("@/assets/images/star.svg"),
        "alt": "star"
      }
    })]), _c('div', {
      staticClass: "rratingtimelinecomparison--period"
    }, [_vm.decrease(item.data) ? _c('img', {
      staticClass: "rratingtimelinecomparison--period-up-down",
      attrs: {
        "src": require("@/assets/images/trendingEntities/red-arrow-down.svg"),
        "alt": "arrow"
      }
    }) : _c('img', {
      staticClass: "rratingtimelinecomparison--period-up-down",
      attrs: {
        "src": require("@/assets/images/trendingEntities/green-arrow-up.svg"),
        "alt": "arrow"
      }
    }), _c('span', {
      staticClass: "rratingtimelinecomparison--period-percent"
    }, [_vm._v("%")]), _c('span', {
      staticClass: "rratingtimelinecomparison--period-text"
    }, [_vm._v("Prev. period")]), _c('span', {
      staticClass: "rratingtimelinecomparison--period-rating"
    }, [_vm._v(" " + _vm._s(item.data[0][1].toPrecision(2)) + " ")]), _c('img', {
      staticClass: "rratingtimelinecomparison--period-star",
      attrs: {
        "src": require("@/assets/images/star.svg"),
        "alt": "star"
      }
    })])]);
  }), 0) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }