var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rratingtimelinechart"
  }, [_vm.title ? _c('r-component-header', {
    staticClass: "rratingtimelinechart--title mb-4"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "py-5",
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "200"
    }
  }) : _c('div', {
    staticClass: "rratingtimelinechart--container"
  }, [_c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  }), _c('div', {
    staticClass: "rratingtimelinechart--last-rating"
  }, [_c('span', {
    staticClass: "rratingtimelinechart--last-rating-top"
  }, [_vm._v(" " + _vm._s(_vm.lastValue) + " ")]), _c('span', {
    staticClass: "rratingtimelinechart--last-rating-slash"
  }, [_vm._v("/")]), _c('span', {
    staticClass: "rratingtimelinechart--last-rating-bottom"
  }, [_vm._v(" " + _vm._s(_vm.maxRateValue) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }