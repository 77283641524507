var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rsrdc mb-5"
  }, [_vm.title ? _c('r-component-header', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm._l(_vm.ratings, function (_ref) {
    var ratingValue = _ref.label,
        percentageValue = _ref.value;
    return _c('v-row', {
      key: "rsrdc-row-".concat(ratingValue),
      staticClass: "mt-0"
    }, [_c('v-col', {
      staticClass: "rsrdc--rating clickable",
      on: {
        "click": function click() {
          return _vm.onClickRatingStar({
            value: ratingValue
          });
        }
      }
    }, [_c('r-rating', {
      attrs: {
        "value": ratingValue
      }
    })], 1), _c('v-col', {
      staticClass: "rsrdc--progress-bar"
    }, [_vm.loading ? _c('v-skeleton-loader', {
      attrs: {
        "type": "list-item"
      }
    }) : _c('v-progress-linear', {
      staticClass: "mt-1 clickable",
      attrs: {
        "value": percentageValue * 100,
        "buffer-value": "100",
        "height": "12",
        "background-color": "var(--r-light-grey)",
        "color": "var(--r-positive-color)"
      },
      on: {
        "click": function click() {
          return _vm.onClickRatingStar({
            value: ratingValue
          });
        }
      }
    })], 1), _c('v-col', {
      staticClass: "rsrdc--percentage-text"
    }, [!_vm.loading ? _c('span', [_vm._v(_vm._s(_vm.$n(percentageValue, 'percent')))]) : _vm._e()])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }