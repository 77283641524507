var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "rnavigationdrawer",
    attrs: {
      "permanent": "",
      "expand-on-hover": ""
    }
  }, [_vm.title || _vm.subtitle ? _c('div', [_c('v-list-item', [_c('v-list-item-content', [_vm.title ? _c('v-list-item-title', {
    staticClass: "text-h6"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.subtitle ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]) : _vm._e()], 1)], 1), _c('v-divider')], 1) : _vm._e(), _vm.items.length ? _c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_vm._l(_vm.directories, function (directory) {
    return _c('v-list-group', {
      key: directory.title,
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            domProps: {
              "textContent": _vm._s(directory.title)
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_vm._l(directory.children, function (child) {
      return _c('v-list-item', {
        key: child.title,
        attrs: {
          "link": ""
        },
        on: {
          "click": function click($event) {
            return _vm.onClickItem(child);
          }
        }
      }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(child.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(child.title))])], 1)], 1);
    }), _c('v-divider')], 2);
  }), _vm._l(_vm.singleItems, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.onClickItem(item);
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }