var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs-items', {
    staticClass: "rtabitems content",
    model: {
      value: _vm.selectedTab,
      callback: function callback($$v) {
        _vm.selectedTab = $$v;
      },
      expression: "selectedTab"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }