var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rreviewcard pa-5 mt-3",
    class: {
      'rreviewcard--enabletransitions': _vm.enabletransitions
    },
    attrs: {
      "tile": ""
    }
  }, [_c('div', [_c('v-list-item', {
    staticClass: "pa-0",
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-avatar', {
    staticClass: "pa-0 ma-0 mr-2",
    attrs: {
      "size": "30",
      "color": "grey"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.avatarUrl
    }
  })], 1), _c('v-list-item-content', {
    staticClass: "pb-0"
  }, [_c('p', {
    staticClass: "body-2 font-weight-bold grey--text text--darken-4 ma-0"
  }, [_vm._v(" " + _vm._s(_vm.username) + " "), _c('span', {
    staticClass: "rreviewcard--date grey--text"
  }, [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.date, 'from', 'now')) + " ")])]), _c('v-list-item-subtitle', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.url) + " ")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0 py-1 mx-0 mt-1 d-flex align-end"
  }, [_c('r-rating', {
    staticClass: "px-0 ml-0 mr-2",
    attrs: {
      "value": _vm.rating
    }
  }), _c('b', {
    staticClass: "black--text pt-1"
  }, [_vm._v(_vm._s(_vm.showOriginal ? _vm.originalTitle : _vm.title))])], 1), _c('v-card-text', {
    key: "".concat(_vm.reviewId, "-").concat(_vm.naturalMaxHeight),
    ref: "textwrapper",
    staticClass: "black--text pa-0 rreviewcard--textwrapper my-1",
    style: {
      'max-height': "".concat(_vm.cardHeight, "px")
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reviewText)
    }
  }), _vm.showReadMoreLink ? _c('a', {
    staticClass: "d-block py-1",
    on: {
      "click": _vm.onClickReadMore
    }
  }, [_vm._v(" " + _vm._s("Read ".concat(_vm.expandText ? 'less' : 'more')) + " "), _c('v-icon', {
    staticStyle: {
      "float": "right",
      "margin-top": "2px"
    },
    attrs: {
      "color": "primary",
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.expandText ? 'expand_less' : 'expand_more') + " ")])], 1) : _vm._e(), _c('v-card-actions', {
    staticClass: "px-0 pt-3 pb-2",
    staticStyle: {
      "color": "#aaa"
    }
  }, [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    style: {
      color: _vm.sentimentColor
    },
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.readableSentiment) + " ")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.sentiment) + " ")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "4",
      "align": "right"
    }
  }, [_vm.language && _vm.language !== 'en' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('a', _vm._g({
          staticClass: "flag-icon",
          class: _vm.flagClass,
          on: {
            "click": function click($event) {
              _vm.showOriginal = !_vm.showOriginal;

              _vm.addReadMore();
            }
          }
        }, on))];
      }
    }], null, false, 1405717097)
  }, [_c('span', [_vm._v("Translated from " + _vm._s(_vm.readableLanguage))])]) : _vm._e()], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }