var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    key: _vm.label,
    staticClass: "rtabitem content"
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }