var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "rwordcloud",
    staticClass: "rwordcloud",
    class: {
      'rwordcloud--entitymapcontrols': !_vm.disableEntityMapControls
    },
    style: {
      height: "".concat(_vm.height, "px")
    }
  }, [_vm._l(_vm.words, function (word, i) {
    return _c('span', {
      key: "word-".concat(_vm.rendercount, "-").concat(word._id, "-").concat(word.selected),
      ref: "word",
      refInFor: true,
      staticClass: "rwordcloud--word",
      class: {
        'rwordcloud--word-group': word.isEntityGroup,
        'rwordcloud--selected': word.selected
      },
      style: {
        fontSize: "".concat(word.size, "px"),
        top: word.position ? "".concat(word.position.y, "px") : '',
        left: word.position ? "".concat(word.position.x, "px") : '',
        visibility: word.visible ? 'visible' : 'hidden',
        color: _vm.wordColor(word),
        borderColor: _vm.borderColor(word, i),
        borderWidth: _vm.borderWidth(word),
        borderStyle: 'solid',
        'border-radius': '6px'
      },
      attrs: {
        "data-wordid": word._id
      },
      on: {
        "click": function click($event) {
          return _vm.onClickWord($event, word);
        }
      }
    }, [_c('span', {
      staticClass: "rwordcloud--word--hovericon",
      on: {
        "click": function click($event) {
          return _vm.onClickWordHoverIcon(word);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(word.isEntityGroup ? 'edit' : 'check')
      }
    })], 1), _vm._v(" " + _vm._s(word.label) + " ")]);
  }), _vm.showLoadingIndication ? _c('div', {
    staticClass: "rwordcloud--loading"
  }) : _vm._e(), _vm.hoveredWord ? _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showEntityGroupPopup,
      expression: "showEntityGroupPopup"
    }],
    staticClass: "rwordcloud--group-popup pa-4",
    style: {
      left: "".concat(_vm.popupX, "px"),
      top: "".concat(_vm.popupY, "px")
    }
  }, [_c('p', [_c('b', [_vm._v(_vm._s(_vm.hoveredWord.label))]), _vm._v(" contains the entities ")]), _vm._l(_vm.groupEntities.slice(0, 3), function (entity) {
    return _c('r-chip', {
      key: "entity-chip-".concat(entity),
      staticClass: "mr-2 mb-2",
      staticStyle: {
        "display": "inline-block"
      },
      attrs: {
        "label": entity
      }
    });
  }), _vm.groupEntities.length > 3 ? _c('r-chip', {
    attrs: {
      "label": "+ ".concat(_vm.groupEntities.length - 3, " more"),
      "outlined": ""
    }
  }) : _vm._e()], 2) : _vm._e(), !_vm.words.length ? _c('r-no-results-message') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }