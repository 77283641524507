var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rgroupedtabs"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex rgroupedtabs--container"
  }, _vm._l(_vm.items, function (group, index) {
    return _c('div', {
      key: group.group,
      staticClass: "d-flex",
      style: _vm.getGroupTabColor(index)
    }, [_c('div', {
      staticClass: "v-tab v-tabs d-flex align-center justify-center",
      class: _vm.isTabActive(index),
      style: {
        'background-color': "".concat(group.color, " !important")
      },
      on: {
        "click": function click(e) {
          return _vm.onGroupTabChanged(e, index);
        }
      }
    }, [_c('span', {
      staticClass: "text-truncate",
      attrs: {
        "title": group.group
      }
    }, [_vm._v(" " + _vm._s(group.group) + " ")]), _vm.showDeleteGroup && _vm.showDeleteGroupItem(index) ? _c('v-icon', {
      staticClass: "rgroupedtabs--delete-icon ml-2",
      attrs: {
        "title": "Remove Group Tab",
        "dense": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('on:delete-group', {
            value: index
          });
        }
      }
    }, [_vm._v(" close ")]) : _vm._e()], 1), _vm.selectedGroupTab === index ? _c('v-tabs', {
      attrs: {
        "background-color": "transparent"
      },
      model: {
        value: _vm.tab,
        callback: function callback($$v) {
          _vm.tab = $$v;
        },
        expression: "tab"
      }
    }, _vm._l(group.items, function (it, ind) {
      return _c('v-tab', {
        key: it.key,
        style: _vm.getSubTabColor(ind, index),
        on: {
          "change": function change() {
            return _vm.onChangeSubTab(ind);
          }
        }
      }, [_c('span', {
        staticClass: "text-truncate",
        attrs: {
          "title": it.label
        }
      }, [_vm._v(" " + _vm._s(it.label) + " ")])]);
    }), 1) : _vm._e(), _vm.items.length - 1 > index ? _c('div', {
      staticClass: "rgroupedtabs--vertical-row"
    }) : _vm._e()], 1);
  }), 0), _vm.hasSeparateTabSlot ? _c('div', {
    staticClass: "rgroupedtabs--separate--tab"
  }, [_vm._t("separateTab")], 2) : _vm._e()]), _vm._t("items")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }