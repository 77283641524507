var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rdatabrandcard box-shadow-custom",
    attrs: {
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "rdatabrandcard--header d-flex mb-2"
  }, [_c('span', {
    staticClass: "rdatabrandcard--title text-truncate",
    attrs: {
      "title": _vm.name
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c('v-icon', {
    staticClass: "rdatabrandcard--icon ml-2"
  }, [_vm._v(" " + _vm._s(_vm.DASHBOARD_TYPE_TO_ICON[_vm.DASHBOARD_TYPES.BRAND]) + " ")])], 1), _c('div', {
    staticClass: "rdatabrandcard--body d-flex"
  }, [_c('div', {
    staticClass: "flex-expand"
  }, [_c('p', {
    staticClass: "rdatabrandcard--numproducts mb-1"
  }, [_vm._v(" " + _vm._s(_vm.numProducts !== 1 ? _vm.$t('resources.productCount', {
    numProducts: _vm.numProducts
  }) : '1' + _vm.$t('resources.product')) + " ")]), _c('p', {
    staticClass: "rdatabrandcard--numurls mb-0"
  }, [_vm._v(" " + _vm._s(_vm.numUrls !== 1 ? _vm.$t('resources.urlCount', {
    numUrls: _vm.numUrls
  }) : '1' + _vm.$t('resources.url')) + " ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }