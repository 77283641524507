var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rdaterangepicker",
    style: _vm.zIndexStyle
  }, [_c('div', {
    staticClass: "rdaterangepicker--box"
  }, [_c('date-picker', {
    ref: "rdaterangepickerRef",
    attrs: {
      "open": false,
      "inline": "",
      "type": "date",
      "range": ""
    },
    on: {
      "change": _vm.handleDateChange
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "rdaterangepicker--header-quick-actions"
        }, [_c('div', {
          staticClass: "rdaterangepicker--header-quick-actions-title"
        }, [_vm._v(" " + _vm._s(_vm.$t('rdateRange.quickSelect')) + " ")]), _c('div', {
          staticClass: "rdaterangepicker--header-quick-actions-buttons-container"
        }, _vm._l(_vm.quickSelections, function (action, index) {
          return _c('button', {
            key: action.label,
            staticClass: "rdaterangepicker--header-quick-actions-buttons",
            class: {
              active: action.isActive
            },
            on: {
              "click": function click() {
                return _vm.onClickQuickAction(action.value, index);
              }
            }
          }, [_vm._v(" " + _vm._s(action.label) + " ")]);
        }), 0)]), _c('div', {
          staticClass: "d-flex pt-5"
        }, [_c('div', {
          staticClass: "rdaterangepicker--header-start-end"
        }, [_c('div', [_vm._v(_vm._s(_vm.$t('global.start')))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.inputValueFrom,
            expression: "inputValueFrom"
          }],
          ref: "inputValueFromRef",
          staticClass: "rdaterangepicker--header-input",
          attrs: {
            "max": _vm.inputValueTo,
            "name": "start",
            "type": "date"
          },
          domProps: {
            "value": _vm.inputValueFrom
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.inputValueFrom = $event.target.value;
            }
          }
        })]), _c('div', {
          staticClass: "rdaterangepicker--header-start-end rdaterangepicker--header-end"
        }, [_c('div', [_vm._v(_vm._s(_vm.$t('global.end')))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.inputValueTo,
            expression: "inputValueTo"
          }],
          ref: "inputValueToRef",
          staticClass: "rdaterangepicker--header-input",
          attrs: {
            "name": "end",
            "type": "date"
          },
          domProps: {
            "value": _vm.inputValueTo
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.inputValueTo = $event.target.value;
            }
          }
        })])])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "rdaterangepicker--footer"
        }, [_c('button', {
          staticClass: "rdaterangepicker--footer-button cancel",
          on: {
            "click": _vm.handleCancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('global.actions.cancel')) + " ")]), _c('button', {
          staticClass: "rdaterangepicker--footer-button apply",
          attrs: {
            "disabled": !_vm.isValid
          },
          on: {
            "click": _vm.handleApplyClick
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('global.actions.apply')) + " ")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }