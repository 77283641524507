var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "raccordion"
  }, [_vm._t("default", null, {
    "activeItem": _vm.selectedItemIndex,
    "onChangeActive": _vm.onClickItem
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }