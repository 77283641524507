var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtabs content"
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "transparent",
      "color": "basil",
      "grow": ""
    },
    on: {
      "change": _vm.onChange
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm.hasStartSlot ? _c('div', {
    staticClass: "rtabs--start-slot"
  }, [_vm._t("start")], 2) : _vm._e(), _vm._l(_vm.items, function (item) {
    return _c('v-tab', {
      key: item.key,
      attrs: {
        "data-testid": "tab-".concat(item.label)
      }
    }, [item.icon ? _c('v-icon', {
      class: {
        'icon-transformed': item.icon === 'mdi-tag',
        'icon': item.icon !== 'mdi-tag'
      },
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(item.label) + " ")], 1);
  })], 2), _c('div', {
    staticClass: "rtabs--end-slot"
  }, [_vm._t("end")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }